import React from 'react';
import './PackagesList.css';
// import projectImage from '../assets/profile.jpg';
import { ReactComponent as GithubLogo } from '../assets/github.svg';
import { ReactComponent as NpmLogo } from '../assets/npm.svg';
import { ReactComponent as CodeSandbox } from '../assets/codesandbox.svg';
import { ReactComponent as WebsiteLogo } from '../assets/website.svg';

const OpenSourcePackages = [
    {
        name: 'smart-env-switcher',
        description: 'SmartEnvSwitcher is a lightweight Node.js library for managing environment variables across multiple environments, such as development, staging, and production. It simplifies the process of switching between environments and updating `.env` files programm',
        type: "npm",
        code: 'npm i smart-env-switcher',
        logo: NpmLogo,
        keywords: ["nodejs",
            "environment",
            "switcher",
            "env",
            "typescript"],
        availableOn: [
            { name: 'npm', link: 'https://www.npmjs.com/package/smart-env-switcher', title: "View on npm" },
            { name: 'github', link: 'https://github.com/Amit2197/smart-env-switcher', title: "View source code" },
            { name: 'codesandbox', link: 'https://codesandbox.io/embed/smart-env-switcher-test-yx5lfp', title: "View demo" },
            { name: 'devamit.in', link: '/packages/smart-env-switcher', title: "View documentation" },]
    },
    {
        name: "markdown-htmlify",
        description:
            "A versatile Node.js package for converting Markdown to HTML. Fetch Markdown content from a URL, local file, or text string, and render it into HTML.",
        type: "npm",
        code: "npm i markdown-htmlify",
        logo: NpmLogo,
        keywords: [
            "markdown",
            "renderer",
            "html",
            "nodejs",
            "typescript",
            "html",
        ],
        availableOn: [
            {
                name: "npm",
                link: "https://www.npmjs.com/package/markdown-htmlify",
                title: "View on npm",
            },
            {
                name: "github",
                link: "https://github.com/Amit2197/markdown-htmlify",
                title: "View source code",
            },
            {
                name: "codesandbox",
                link: "https://codesandbox.io/embed/markdown-htmlify-test-yx5lfp",
                title: "View demo",
            },
            {
                name: "devamit.in",
                link: "/packages/markdown-htmlify",
                title: "View documentation",
            },
        ],
    },
    {
        name: "markdown-htmlify-react",
        description:
            "A React component to convert Markdown to HTML, supporting input from URL, local file, or string.",
        type: "npm",
        code: "npm i markdown-htmlify-react",
        logo: NpmLogo,
        keywords: [
            "react",
            "markdown",
            "html",
            "converter",
            "markdown-to-html",
            "react-component"
        ],
        availableOn: [
            {
                name: "npm",
                link: "https://www.npmjs.com/package/markdown-htmlify-react",
                title: "View on npm",
            },
            {
                name: "github",
                link: "https://github.com/Amit2197/markdown-htmlify-react",
                title: "View source code",
            },
            {
                name: "codesandbox",
                link: "https://codesandbox.io/embed/markdown-htmlify-react-test-yx5lfp",
                title: "View demo",
            },
            {
                name: "devamit.in",
                link: "/packages/markdown-htmlify-react",
                title: "View documentation",
            },
        ],
    },
    {
          name: "react-enhanced-text-editor",
          description:
            "A simple and customizable rich text editor built with React. This editor supports various text formatting options and media embedding, making it suitable for a wide range of applications.",
          type: "npm",
          code: "npm i react-enhanced-text-editor",
          logo: NpmLogo,
          keywords: [
            "react", "rich-text-editor", "editor", "text-format", "tingwysiwyg", "text-editor"
          ],
          availableOn: [
            {
              name: "npm",
              link: "https://www.npmjs.com/package/react-enhanced-text-editor",
              title: "View on npm",
            },
            {
              name: "github",
              link: "https://github.com/Amit2197/react-enhanced-text-editor",
              title: "View source code",
            },
            {
              name: "codesandbox",
              link: "https://codesandbox.io/embed/react-enhanced-text-editor-test-yx5lfp",
              title: "View demo",
            },
            {
              name: "devamit.in",
              link: "/packages/react-enhanced-text-editor",
              title: "View documentation",
            },
          ],
        },
];

const PackagesList = () => {
    const [onHoverKeywords, setOnHoverKeywords] = React.useState(null);

    return (
        <div className="section_npm-packages">
            <h2>Open Source NPM Packages</h2>
            <div className="package-list">
                {OpenSourcePackages.map((Pkg, index) => (
                    <div key={index} className="package-item">
                        <div className="package-logo">
                        <Pkg.logo className='logo'/>
                        </div>
                        <div style={{flex: 1}}>
                            <a href={Pkg.link} target="_blank" rel="noopener noreferrer">
                                <h3>{Pkg.name}</h3>
                                <p>{Pkg.description}</p>
                            </a>
                            <pre><code>{Pkg.code}</code></pre>
                            <div className='keywords'>
                                {Pkg.keywords.map((keyword, key_index) => (
                                    <span key={key_index}>{keyword}</span>
                                ))}
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: "column", alignItems: "flex-end", gap: '1rem', justifyContent: 'center' }}>
                            {Pkg.availableOn.map((Available, av_index) => (
                                <div key={av_index} style={{ position: "relative", }} onMouseOver={() => setOnHoverKeywords([index, av_index])} onMouseOut={() => setOnHoverKeywords(null)}>
                                    {Available.name === 'npm' ? (
                                        <a href={Available.link} target="_blank" rel="noopener noreferrer" style={{ textDecorationColor: "#ffdd57", color: "#ffdd57", borderRadius: 20, width: 32, height: 32, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "#444", boxShadow: 'rgba(21, 21, 21, 0.7) 3px 2px 2px 1px', overflow: "hidden" }}>
                                            <NpmLogo className='logo' width={32} height={32} />
                                        </a>
                                    ) : Available.name === 'github' ? (
                                        <a href={Available.link} target="_blank" rel="noopener noreferrer" style={{ textDecorationColor: "#ffdd57", color: "#ffdd57", borderRadius: 20, width: 32, height: 32, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "#444", boxShadow: 'rgba(21, 21, 21, 0.7) 3px 2px 2px 1px', overflow: "hidden" }}>
                                            <GithubLogo className='logo' width={32} height={32} />
                                        </a>
                                    ) : Available.name === 'devamit.in' ? (
                                        <a href={Available.link} rel="noopener noreferrer" style={{ textDecorationColor: "#ffdd57", color: "#ffdd57", borderRadius: 20, width: 32, height: 32, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "#444", boxShadow: 'rgba(21, 21, 21, 0.7) 3px 2px 2px 1px', overflow: "hidden" }}>
                                            <WebsiteLogo className='logo' width={32} height={32} stroke='#fff' />
                                        </a>
                                    ) : (
                                        <a href={Available.link} target="_blank" rel="noopener noreferrer" style={{ textDecorationColor: "#ffdd57", color: "#ffdd57", borderRadius: 20, width: 32, height: 32, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "#444", boxShadow: 'rgba(21, 21, 21, 0.7) 3px 2px 2px 1px', overflow: "hidden" }}>
                                            <CodeSandbox className='logo' width={32} height={32} />
                                        </a>
                                    )
                                    }

                                    {onHoverKeywords && onHoverKeywords?.[0] === index && onHoverKeywords?.[1] === av_index && (
                                        <div style={{ position: "absolute", left: 42, top: 3, display: "inline-block", backgroundColor: "rgba(0, 0, 0, 0.64)", textWrap: "nowrap", padding: "2px 12px", borderRadius: 12 }}>{Available.title}</div>
                                    )
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                ))}

            </div>
        </div>
    );
};

export default PackagesList;