import React, { use, useEffect, useState } from 'react'
import { notebookData } from "../data/notebookData";
import { MarkdownConverter } from 'markdown-htmlify-react';

const PlayNotesQuize = () => {
    const [selectedLanguage, setSelectedLanguage] = useState("python");
    const [randomQuestions, setRandomQuestions] = useState({});

    useEffect(() => {
        getRandom()
    }, [selectedLanguage]);

    const getRandom = () => {
        const questions = notebookData?.[selectedLanguage]?.pages || [];
        if (questions.length === 0) return;
        const randomQuestionsIndex = Math.floor(Math.random() * questions.length);
        setRandomQuestions(questions[randomQuestionsIndex]);
    }

    const formatText = (text) => {
        const parts = text.split("\n").map((line, index) => {
          if (line.startsWith("**") && line.endsWith("**")) {
            return <b key={index}>{line.slice(2, -2)}</b>;
          } else if (line.startsWith("- ")) {
            return <li key={index}>{line.slice(2)}</li>;
          } else {
            return <p key={index}>{line}</p>;
          }
        });
      
        return <>{parts}</>;
        };

    const renderContent = (content) => {
        return content?.map((item, index) => {
            switch (item.type) {
                case "text":
                    return <p key={index} style={styles.p}>{formatText(item.value)}</p>;
                case "code":
                    return (
                        <pre key={index} style={styles.pre}>
                            <code style={styles.code}>{item.value}</code>
                        </pre>
                    );
                case "image":
                    return <img key={index} src={item.value} alt="content" style={styles.image} />;
                default:
                    return null;
            }
        });
    };

    return (
        <div style={{ backgroundColor: "#000", minHeight: "100%" }}>

            <div style={{ textAlign: "center", paddingTop: "80px" }}>
                <h1 style={{ color: "#fff" }}>Play Quize</h1>
                <select style={{  borderRadius: "5px", backgroundColor: "#000", color: "#fff" }} onChange={(e) => setSelectedLanguage(e.target.value)}>
                    <option value="python">Python</option>
                    <option value="javascript">JavaScript</option>
                    <option value="nodejs">NodeJs</option>
                    <option value="typescript">Typescript</option>
                    <option value="database">Database</option>
                </select>   
                
                <div style={styles.note}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
                    <h2 style={styles.h2}>{randomQuestions.description}</h2>
                    <button style={{backgroundColor: "#00e676", borderRadius: "5px", padding: "10px", color: "#000", cursor: "pointer"}} onClick={()=>{getRandom()}}>Next</button>
                    </div>
                    
                    {renderContent(randomQuestions.content)}
                </div>
            </div>
        </div>
    )
}

const styles = {
    container: {
        flex: 1,
        padding: "20px",
        overflowY: "auto",
        backgroundColor: "#121212",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        
        marginInline: "auto",
        color: "#fff",
    },
    note: {
        marginBottom: "20px",
        maxWidth: "800px",
        marginInline: "auto",
        color:"#000",
        backgroundColor: "#fff",
        padding: "20px",
        textAlign: "left",

    },
    pre: {
        backgroundColor: "#1e1e1e",
        padding: "10px",
        borderRadius: "5px",
        overflowX: "auto",
        marginBottom: "10px",
    },
    code: {
        fontFamily: "monospace",
        color: "#00e676", // Customize code color
    },
    image: {
        maxWidth: "100%",
        height: "auto",
        margin: "10px 0",
        borderRadius: "5px",
    },
    h2: {
        color: "#000",
        borderBottom: "2px solid #333",
        paddingBottom: "5px",
        marginBottom: "10px",
        flex: 1
    },
    p: {
        lineHeight: 1.6,
        marginBottom: "10px",
    },
};

export default PlayNotesQuize