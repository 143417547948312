import React from 'react';
import Projects from '../components/Projects';

const ProjectsPage = () => {
    return (
        <div>
            <Projects />
        </div>
    );
};

export default ProjectsPage;