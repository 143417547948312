export const notebookData = {
  python: {
    pages: [
      {
        description: "Introduction to Python",
        content: [
          { type: "text", value: "Python is a high-level, interpreted programming language known for its simplicity and readability. It is widely used in web development, data science, artificial intelligence, and more." },
          { type: "code", value: "print('Hello, Python!')" },
        ],
      },
      {
        description: "Python Variables & Data Types",
        content: [
          { type: "text", value: "Python variables are containers for storing data values. The data types include strings, numbers, booleans, lists, tuples, dictionaries, and more." },
          { type: "code", value: "message = 'Hello, World!'\nnumber = 42\nis_true = True" },
        ],
      },
      {
        description: "Python Functions & Lambda Functions",
        content: [
          { type: "text", value: "Functions in Python are blocks of reusable code that perform a specific task. Lambda functions are anonymous functions defined using the lambda keyword." },
          { type: "code", value: "def greet(name):\n    return f'Hello, {name}!'\n\nlambda name: f'Hello, {name}!'" },
        ],
      },
      {
        description: "Python Lists & List Comprehensions",
        content: [
          { type: "text", value: "Lists in Python are ordered collections of items. List comprehensions provide a concise way to create lists based on existing lists." },
          { type: "code", value: "numbers = [1, 2, 3, 4, 5]\nnew_numbers = [n * 2 for n in numbers]" },
        ],
      },
      {
        description: "Python Dictionaries & Dictionary Methods",
        content: [
          { type: "text", value: "Dictionaries in Python are unordered collections of key-value pairs. Dictionary methods like get, keys, values, and items are used for dictionary manipulation." },
          { type: "code", value: "person = {'name': 'Alice', 'age': 30}\nprint(person['name'])" },
        ],
      },
      {
        description: "Control Flow & Conditional Statements",
        content: [
          { type: "text", value: "Control flow statements like if, elif, and else are used to make decisions in Python based on conditions." },
          { type: "code", value: "if x > 0:\n    print('Positive')\nelif x < 0:\n    print('Negative')\nelse:\n    print('Zero')" },
        ],
      },
      {
        description: "Loops & Iteration",
        content: [
          { type: "text", value: "Loops in Python are used to iterate over sequences like lists, tuples, and dictionaries. Common loop types include for loops and while loops." },
          { type: "code", value: "for i in range(5):\n    print(i)\n\nwhile x < 10:\n    print(x)\n    x += 1" },
        ],
      },
      {
        description: "File Handling & I/O Operations",
        content: [
          { type: "text", value: "Python provides built-in functions and modules for file handling and input/output operations. This includes reading from and writing to files." },
          { type: "code", value: "with open('file.txt', 'r') as file:\n    data = file.read()\n\nwith open('output.txt', 'w') as file:\n    file.write('Hello, File!')" },
        ],
      },
      {
        description: "Modules & Packages",
        content: [
          { type: "text", value: "Modules in Python are files containing Python code that can be imported and used in other Python files. Packages are directories containing multiple modules." },
          { type: "code", value: "import math\nfrom datetime import datetime" },
        ],
      },
      {
        description: "Error Handling & Exceptions",
        content: [
          { type: "text", value: "Error handling in Python is done using try, except, else, and finally blocks. Exceptions are raised when errors occur during program execution." },
          { type: "code", value: "try:\n    result = x / y\nexcept ZeroDivisionError as e:\n    print('Error:', e)" },
        ],
      },
    ],
  },
  javascript: {
    pages: [
      {
        "description": "What is JavaScript?",
        "content": [
          {
            "type": "text",
            "value": "JavaScript is a high-level, interpreted programming language used to create interactive web applications. It supports multiple paradigms, including object-oriented, imperative, and functional programming. JavaScript runs in web browsers and is widely used for dynamic content, games, server-side applications, and mobile development."
          },
          {
            "type": "code",
            "value": "<script>\n  document.write('Hello, World!');\n</script>"
          }
        ]
      },
      {
        "description": "Difference between let, const, and var",
        "content": [
          {
            "type": "text",
            "value": "`var`: Function-scoped, hoisted, can be re-declared and updated, but not recommended due to potential bugs."
          },
          {
            "type": "text",
            "value": "`let`: Block-scoped, hoisted but not initialized, cannot be re-declared, making it safer for variable management."
          },
          {
            "type": "text",
            "value": "`const`: Block-scoped, hoisted but not initialized, cannot be updated or re-declared, ensuring immutable references."
          },
          {
            "type": "code",
            "value": "var x = 10;\nlet y = 20;\nconst z = 30;"
          }
        ]
      },
      {
        "description": "How does type coercion work in JavaScript?",
        "content": [
          {
            "type": "text",
            "value": "Type coercion automatically converts values from one type to another, either implicitly or explicitly."
          },
          {
            "type": "text",
            "value": "Implicit coercion occurs in operations, such as `1 + '2'` resulting in `'12'` (string concatenation)."
          },
          {
            "type": "text",
            "value": "Explicit coercion uses functions like `Number()`, `String()`, and `Boolean()` to convert types manually."
          },
          {
            "type": "code",
            "value": "console.log(1 + '2'); // '12'\nconsole.log(Number('2') + 1); // 3"
          }
        ]
      },
      {
        "description": "Difference between `==` and `===`",
        "content": [
          {
            "type": "text",
            "value": "`==` (loose equality) performs type conversion before comparison."
          },
          {
            "type": "text",
            "value": "`===` (strict equality) does not perform type conversion and compares both value and type."
          },
          {
            "type": "code",
            "value": "console.log(1 == '1'); // true\nconsole.log(1 === '1'); // false"
          }
        ]
      },
      {
        "description": "What are truthy and falsy values?",
        "content": [
          {
            "type": "text",
            "value": "Falsy values: `false`, `0`, `''`, `null`, `undefined`, `NaN` (evaluated as false in boolean contexts)."
          },
          {
            "type": "text",
            "value": "Truthy values: Any value that is not falsy, such as non-empty strings, numbers, and objects."
          },
          {
            "type": "code",
            "value": "if (0) console.log('Falsy'); // Won't execute\nif ('Hello') console.log('Truthy'); // Executes"
          }
        ]
      },
      {
        "description": "What is the `typeof` operator?",
        "content": [
          {
            "type": "text",
            "value": "The `typeof` operator returns a string representing the type of a value."
          },
          {
            "type": "text",
            "value": "Common return values: `'string'`, `'number'`, `'boolean'`, `'object'`, `'undefined'`."
          },
          {
            "type": "code",
            "value": "console.log(typeof 42); // 'number'\nconsole.log(typeof {}); // 'object'"
          }
        ]
      },
      {
        "description": "JavaScript Data Types",
        "content": [
          {
            "type": "text",
            "value": "JavaScript has two main types of data: primitive and non-primitive."
          },
          {
            "type": "text",
            "value": "Primitive types: `Number`, `String`, `Boolean`, `Undefined`, `Null`, `BigInt`, `Symbol`."
          },
          {
            "type": "text",
            "value": "Non-primitive types: `Object`, `Function`, `Array`."
          }
        ]
      },
      {
        "description": "Difference between `null`, `undefined`, and `''`",
        "content": [
          {
            "type": "text",
            "value": "`null`: Represents an intentional absence of a value."
          },
          {
            "type": "text",
            "value": "`undefined`: A variable declared but not assigned any value."
          },
          {
            "type": "text",
            "value": "`''`: An empty string, but still a defined value."
          }
        ]
      },
      {
        "description": "How to check if a variable is an array?",
        "content": [
          {
            "type": "text",
            "value": "Different ways to check if a variable is an array:"
          },
          {
            "type": "text",
            "value": "- `Array.isArray(value)` (recommended)"
          },
          {
            "type": "text",
            "value": "- `value instanceof Array`"
          },
          {
            "type": "text",
            "value": "- `value.constructor === Array`"
          },
          {
            "type": "text",
            "value": "- `Object.prototype.toString.call(value) === '[object Array]'`"
          },
          {
            "type": "code",
            "value": "console.log(Array.isArray([])); // true\nconsole.log([] instanceof Array); // true"
          }
        ]
      },
      {
        "description": "What is scope in JavaScript?",
        "content": [
          {
            "type": "text",
            "value": "Scope determines the accessibility of variables in JavaScript."
          },
          {
            "type": "text",
            "value": "Two main types:"
          },
          {
            "type": "text",
            "value": "- **Global Scope**: Variables accessible anywhere in the script."
          },
          {
            "type": "text",
            "value": "- **Local Scope**: Variables accessible only within a function or block."
          },
          {
            "type": "code",
            "value": "let name = 'John';\nfunction greet() {\n    console.log('Hello ' + name + '!');\n}\ngreet(); // 'Hello John!'\nconsole.log(name); // 'John'"
          },
          {
            "type": "code",
            "value": "function greetLocal() {\n    let name = 'Doe';\n    console.log('Hello ' + name + '!');\n}\ngreetLocal(); // 'Hello Doe'\n// console.log(name); // ReferenceError"
          }
        ]
      },
      {
        "description": "Function scope vs. Block scope",
        "content": [
          {
            "type": "text",
            "value": "Function Scope: Variables declared inside a function are accessible only within that function."
          },
          {
            "type": "text",
            "value": "Block Scope: Variables declared with `let` or `const` inside a block `{}` are accessible only within that block."
          },
          {
            "type": "code",
            "value": "function greetFunc() {\n    let city = 'New York';\n    console.log(city);\n}\ngreetFunc(); // 'New York'\n// console.log(city); // ReferenceError"
          },
          {
            "type": "code",
            "value": "if (true) {\n    let country = 'USA';\n    console.log(country); // 'USA'\n}\n// console.log(country); // ReferenceError"
          }
        ]
      },
      {
        "description": "What is hoisting in JavaScript?",
        "content": [
          {
            "type": "text",
            "value": "Hoisting moves variable and function declarations to the top of their scope before execution."
          },
          {
            "type": "text",
            "value": "- `var` is hoisted but initialized as `undefined`."
          },
          {
            "type": "text",
            "value": "- `let` and `const` are hoisted but remain in the Temporal Dead Zone (TDZ) until assigned."
          },
          {
            "type": "text",
            "value": "- Function declarations are fully hoisted and can be called before they appear in the code."
          },
          {
            "type": "code",
            "value": "console.log(name); // undefined\nvar name = 'John';"
          },
          {
            "type": "code",
            "value": "// console.log(age); // ReferenceError: Cannot access 'age' before initialization\nlet age = 25;"
          },
          {
            "type": "code",
            "value": "console.log(greet()); // 'Hello!'\nfunction greet() {\n    return 'Hello!';\n}"
          }
        ]
      },
      {
        "description": "Hoisting with var, let, and const",
        "content": [
          {
            "type": "text",
            "value": "Hoisting moves declarations to the top of their scope before execution."
          },
          {
            "type": "text",
            "value": "- **var**: Hoisted and initialized with `undefined`, accessible before declaration."
          },
          {
            "type": "text",
            "value": "- **let & const**: Hoisted but not initialized, accessing before declaration throws an error."
          },
          {
            "type": "code",
            "value": "console.log(city); // undefined\nvar city = 'Paris';"
          },
          {
            "type": "code",
            "value": "// console.log(country); // ReferenceError: Cannot access 'country' before initialization\nlet country = 'France';"
          }
        ]
      },
      {
        "description": "What is the Temporal Dead Zone (TDZ)?",
        "content": [
          {
            "type": "text",
            "value": "The period between variable hoisting and initialization where access causes a ReferenceError."
          },
          {
            "type": "text",
            "value": "Applies to `let` and `const`, but not `var`."
          },
          {
            "type": "code",
            "value": "console.log(score); // ReferenceError: Cannot access 'score' before initialization\nlet score = 100;"
          }
        ]
      },
      {
        "description": "What is a function in JavaScript?",
        "content": [
          {
            "type": "text",
            "value": "A reusable block of code that executes when called."
          },
          {
            "type": "text",
            "value": "Functions can take parameters and return a value."
          },
          {
            "type": "text",
            "value": "Defined using the `function` keyword."
          },
          {
            "type": "code",
            "value": "function sayHello() {\n    console.log('Hello, world!');\n}\nsayHello(); // 'Hello, world!'"
          }
        ]
      },
      {
        "description": "What are first-class functions?",
        "content": [
          {
            "type": "text",
            "value": "Functions in JavaScript are first-class citizens, meaning they can:"
          },
          {
            "type": "text",
            "value": "- Be assigned to variables."
          },
          {
            "type": "text",
            "value": "- Be passed as arguments."
          },
          {
            "type": "text",
            "value": "- Be returned from functions."
          },
          {
            "type": "code",
            "value": "const greetFunc = function() {\n    return 'Hello!';\n};\nconsole.log(greetFunc()); // 'Hello!'"
          }
        ]
      },
      {
        "description": "What is a higher-order function?",
        "content": [
          {
            "type": "text",
            "value": "A function that takes another function as an argument or returns a function."
          },
          {
            "type": "code",
            "value": "function greet() {\n    return function() {\n        console.log('Hello World!');\n    };\n}\nlet sayHello = greet();\nsayHello(); // 'Hello World!'"
          }
        ]
      },
      {
        "description": "What is a closure in JavaScript?",
        "content": [
          {
            "type": "text",
            "value": "A closure is a function that retains access to its outer function’s variables even after the outer function has executed."
          },
          {
            "type": "code",
            "value": "function greet() {\n    let name = 'John';\n    return function() {\n        console.log('Hello ' + name + '!');\n    };\n}\nlet sayHello = greet();\nsayHello(); // 'Hello John!'"
          }
        ]
      },
      {
        "description": "What is the use of closures in JavaScript?",
        "content": [
          {
            "type": "text",
            "value": "Closures are used for:"
          },
          {
            "type": "text",
            "value": "- Data encapsulation (private variables)"
          },
          {
            "type": "text",
            "value": "- Creating factory functions"
          },
          {
            "type": "text",
            "value": "- Implementing module patterns"
          },
          {
            "type": "text",
            "value": "- Event handlers & callbacks"
          },
          {
            "type": "code",
            "value": "function counter() {\n    let count = 0;\n    return function() {\n        count++;\n        console.log(count);\n    };\n}\nlet increment = counter();\nincrement(); // 1\nincrement(); // 2\nincrement(); // 3"
          }
        ]
      },
      {
        "description": "Function Expression vs. Function Declaration",
        "content": [
          {
            "type": "text",
            "value": "**Function Expression:**"
          },
          {
            "type": "text",
            "value": "- Assigned to a variable."
          },
          {
            "type": "text",
            "value": "- Not hoisted."
          },
          {
            "type": "text",
            "value": "- Created at runtime."
          },
          {
            "type": "text",
            "value": "- Can be passed as an argument or returned."
          },
          {
            "type": "code",
            "value": "let greet = function() {\n    console.log('Hello World!');\n};\ngreet(); // 'Hello World!'"
          },
          {
            "type": "text",
            "value": "**Function Declaration:**"
          },
          {
            "type": "text",
            "value": "- Declared with `function` keyword."
          },
          {
            "type": "text",
            "value": "- Hoisted (can be called before declaration)."
          },
          {
            "type": "text",
            "value": "- Defined at parse time."
          },
          {
            "type": "code",
            "value": "function greet() {\n    console.log('Hello World!');\n}\ngreet(); // 'Hello World!'"
          }
        ]
      },
      {
        "description": "Arrow Functions vs. Regular Functions",
        "content": [
          {
            "type": "text",
            "value": "**Arrow Functions:**"
          },
          {
            "type": "text",
            "value": "- Shorter syntax"
          },
          {
            "type": "text",
            "value": "- No `this`, `arguments`, or `super`"
          },
          {
            "type": "text",
            "value": "- Cannot be constructors"
          },
          {
            "type": "code",
            "value": "let greet = () => console.log('Hello World!');\ngreet(); // 'Hello World!'"
          },
          {
            "type": "text",
            "value": "**Regular Functions:**"
          },
          {
            "type": "text",
            "value": "- Have their own `this`, `arguments`"
          },
          {
            "type": "text",
            "value": "- Can be used as constructors"
          },
          {
            "type": "code",
            "value": "function greet() {\n    console.log('Hello World!');\n}\ngreet(); // 'Hello World!'"
          }
        ]
      },
      {
        "description": "What is the `arguments` object?",
        "content": [
          {
            "type": "text",
            "value": "- An array-like object containing function arguments."
          },
          {
            "type": "text",
            "value": "- Available in regular functions, not in arrow functions."
          },
          {
            "type": "code",
            "value": "function sum() {\n    let total = 0;\n    for (let i = 0; i < arguments.length; i++) {\n        total += arguments[i];\n    }\n    return total;\n}\nconsole.log(sum(1, 2, 3)); // 6"
          }
        ]
      },
      {
        "description": "Rest Parameter",
        "content": [
          {
            "type": "text",
            "value": "- Captures an indefinite number of arguments as an array."
          },
          {
            "type": "text",
            "value": "- Syntax: `...args`"
          },
          {
            "type": "code",
            "value": "function sum(...args) {\n    return args.reduce((total, current) => total + current, 0);\n}\nconsole.log(sum(1, 2, 3)); // 6"
          }
        ]
      },
      {
        "description": "Spread Operator",
        "content": [
          {
            "type": "text",
            "value": "- Expands an array into individual elements."
          },
          {
            "type": "text",
            "value": "- Syntax: `...array`"
          },
          {
            "type": "code",
            "value": "function sum(a, b, c) {\n    return a + b + c;\n}\nlet numbers = [1, 2, 3];\nconsole.log(sum(...numbers)); // 6"
          }
        ]
      },
      {
        "description": "Default Parameters",
        "content": [
          {
            "type": "text",
            "value": "- Assigns a default value if no argument is passed."
          },
          {
            "type": "code",
            "value": "function greet(name = 'World') {\n    console.log('Hello ' + name + '!');\n}\ngreet(); // 'Hello World!'\ngreet('John'); // 'Hello John!'"
          }
        ]
      },
      {
        "description": "The `this` Keyword",
        "content": [
          {
            "type": "text",
            "value": "- Refers to the object that is executing the function."
          },
          {
            "type": "text",
            "value": "- Depends on function execution context."
          },
          {
            "type": "code",
            "value": "let person = {\n    name: 'John',\n    greet: function() {\n        console.log('Hello ' + this.name + '!');\n    }\n};\nperson.greet(); // 'Hello John!'"
          }
        ]
      },
      {
        "description": "Different Ways to Set `this`",
        "content": [
          {
            "type": "text",
            "value": "- **Implicit Binding**: `this` refers to the object before the dot."
          },
          {
            "type": "text",
            "value": "- **Explicit Binding**: `call()`, `apply()`, `bind()` explicitly set `this`."
          },
          {
            "type": "text",
            "value": "- **New Binding**: `new` creates a new object and sets `this`."
          },
          {
            "type": "text",
            "value": "- **Default Binding**: If not bound, `this` refers to the global object."
          }
        ]
      },
      {
        "description": "Call, Apply, and Bind Methods",
        "content": [
          {
            "type": "text",
            "value": "- **call()**: Calls a function with a specific `this` and arguments individually."
          },
          {
            "type": "text",
            "value": "- **apply()**: Calls a function with `this` and arguments as an array."
          },
          {
            "type": "text",
            "value": "- **bind()**: Returns a new function with `this` set to the provided value."
          }
        ]
      },
      {
        "description": "Recursion",
        "content": [
          {
            "type": "text",
            "value": "- Recursion is a technique where a function calls itself to solve problems with smaller subproblems."
          },
          {
            "type": "code",
            "value": "function factorial(n) {\n    return n === 0 ? 1 : n * factorial(n - 1);\n}\nconsole.log(factorial(5)); // 120"
          }
        ]
      },
      {
        "description": "Callback Functions",
        "content": [
          {
            "type": "text",
            "value": "- A callback is a function passed as an argument to another function, commonly used in asynchronous operations."
          },
          {
            "type": "code",
            "value": "function greet(name, callback) {\n    console.log(`Hello ${name}!`);\n    callback();\n}\ngreet('John', () => console.log('How are you?'));"
          }
        ]
      },
      {
        "description": "Promises",
        "content": [
          {
            "type": "text",
            "value": "- A promise represents the eventual result of an asynchronous operation, handling success and failure."
          },
          {
            "type": "code",
            "value": "let promise = new Promise(resolve => setTimeout(() => resolve('Hello World!'), 1000));\npromise.then(console.log);"
          }
        ]
      },
      {
        "description": "Async/Await",
        "content": [
          {
            "type": "text",
            "value": "- async/await simplifies asynchronous code, making it look synchronous, built on promises."
          },
          {
            "type": "code",
            "value": "async function greet() {\n    return 'Hello World!';\n}\ngreet().then(console.log);"
          }
        ]
      },
      {
        "description": "Event Loop",
        "content": [
          {
            "type": "text",
            "value": "- The event loop manages asynchronous operations, ensuring non-blocking execution."
          },
          {
            "type": "code",
            "value": "console.log('Hello World!');\nsetTimeout(() => console.log('How are you?'), 1000);\nconsole.log('Goodbye!');"
          }
        ]
      },
      {
        "description": "Synchronous vs. Asynchronous Code",
        "content": [
          {
            "type": "text",
            "value": "- Synchronous executes sequentially (blocking); asynchronous allows non-blocking execution."
          },
          {
            "type": "code",
            "value": "console.log('Sync:');\nconsole.log('Hello');\nconsole.log('Goodbye');\n\nconsole.log('Async:');\nconsole.log('Hello');\nsetTimeout(() => console.log('How are you?'), 1000);\nconsole.log('Goodbye');"
          }
        ]
      },
      {
        "description": "Promise vs. Async/Await",
        "content": [
          {
            "type": "text",
            "value": "- Promises use `.then()` and `.catch()`; async/await makes code more readable."
          },
          {
            "type": "code",
            "value": "let promiseEx = new Promise(resolve => setTimeout(() => resolve('Hello!'), 1000));\npromiseEx.then(console.log);\n\nasync function asyncExample() {\n    console.log(await promiseEx);\n}\nasyncExample();"
          }
        ]
      },
      {
        "description": "Array Methods: map, filter, reduce",
        "content": [
          {
            "type": "text",
            "value": "- `map` transforms each array element."
          },
          {
            "type": "text",
            "value": "- `filter` returns elements meeting a condition."
          },
          {
            "type": "text",
            "value": "- `reduce` accumulates values into one result."
          }
        ]
      },
      {
        "description": "find vs. findIndex",
        "content": [
          {
            "type": "text",
            "value": "- `find` returns the first matching element; `findIndex` returns its index."
          }
        ]
      },
      {
        "description": "some vs. every",
        "content": [
          {
            "type": "text",
            "value": "- `some` checks if any element meets a condition; `every` checks if all do."
          }
        ]
      },
      {
        "description": "slice vs. splice",
        "content": [
          {
            "type": "text",
            "value": "- `slice` extracts elements without modifying the array."
          },
          {
            "type": "text",
            "value": "- `splice` modifies the array by removing or replacing elements."
          }
        ]
      },
      {
        "description": "push vs. pop",
        "content": [
          {
            "type": "text",
            "value": "- `push`: Adds elements to the end of an array, returns new length, mutates the array."
          },
          {
            "type": "text",
            "value": "- `pop`: Removes the last element, returns it, mutates the array."
          }
        ]
      },
      {
        "description": "unshift vs. shift",
        "content": [
          { "type": "text", "value": "- `unshift`: Adds elements to the beginning, returns new length, mutates the array." },
          { "type": "text", "value": "- `shift`: Removes the first element, returns it, mutates the array." },
          { "type": "code", "value": "let arr = [1, 2, 3];\narr.unshift(0);\nconsole.log(arr); // [0, 1, 2, 3]\n\nlet first = arr.shift();\nconsole.log(first); // 0\nconsole.log(arr); // [1, 2, 3]" }
        ]
      },
      {
        "description": "concat vs. join",
        "content": [
          { "type": "text", "value": "- `concat`: Merges arrays, returns a new array, does not mutate originals." },
          { "type": "text", "value": "- `join`: Converts array to string with a separator, does not mutate the array." },
          { "type": "code", "value": "let nums1 = [1, 2, 3], nums2 = [4, 5, 6];\nconsole.log(nums1.concat(nums2)); // [1, 2, 3, 4, 5, 6]\n\nconsole.log(nums1.join(\", \")); // '1, 2, 3'" }
        ]
      },
      {
        "description": "includes vs. indexOf",
        "content": [
          { "type": "text", "value": "- `includes`: Checks if an element exists, returns true/false." },
          { "type": "text", "value": "- `indexOf`: Returns the index of an element, or -1 if not found." },
          { "type": "code", "value": "let nums = [1, 2, 3];\nconsole.log(nums.includes(2)); // true\nconsole.log(nums.indexOf(2)); // 1" }
        ]
      },
      {
        "description": "Object.keys vs. Object.values",
        "content": [
          { "type": "text", "value": "- `Object.keys`: Returns an array of object keys." },
          { "type": "text", "value": "- `Object.values`: Returns an array of object values." },
          { "type": "code", "value": "let person = { name: 'John', age: 30 };\nconsole.log(Object.keys(person)); // ['name', 'age']\nconsole.log(Object.values(person)); // ['John', 30]" }
        ]
      },
      {
        "description": "Object.assign vs. Spread Operator",
        "content": [
          { "type": "text", "value": "- `Object.assign`: Merges objects, returns a new object." },
          { "type": "text", "value": "- Spread Operator (`...`): Copies properties, shallow clones arrays/objects." },
          { "type": "code", "value": "let obj1 = { name: 'John' }, obj2 = { age: 30 };\nlet merged = Object.assign({}, obj1, obj2);\nconsole.log(merged); // { name: 'John', age: 30 }\n\nlet copy = { ...obj1 };\nconsole.log(copy); // { name: 'John' }" }
        ]
      },
      {
        "description": "What is the DOM?",
        "content": [
          { "type": "text", "value": "The Document Object Model represents the structure of a web page as objects for scripting." },
          { "type": "code", "value": "<!DOCTYPE html>\n<html>\n  <head><title>Document</title></head>\n  <body><h1>Hello World!</h1></body>\n</html>" }
        ]
      },
      {
        "description": "How to select elements in the DOM?",
        "content": [
          { "type": "text", "value": "- `getElementById`: Selects an element by ID." },
          { "type": "text", "value": "- `getElementsByClassName`: Selects elements by class name." },
          { "type": "text", "value": "- `getElementsByTagName`: Selects elements by tag name." },
          { "type": "text", "value": "- `querySelector`: Selects the first matching element." },
          { "type": "text", "value": "- `querySelectorAll`: Selects all matching elements." },
          { "type": "code", "value": "let container = document.getElementById('container');\nlet paragraphs = document.getElementsByClassName('text');\nlet first = document.querySelector('.text');\nlet all = document.querySelectorAll('.text');" }
        ]
      },
      {
        "description": "How to create elements in the DOM?",
        "content": [
          {
            "type": "text",
            "value": "Use `document.createElement()` to create new elements dynamically."
          },
          {
            "type": "code",
            "value": "let div = document.createElement(\"div\");\ndiv.textContent = \"Hello World!\";\ndocument.body.appendChild(div);"
          }
        ]
      },
      {
        "description": "How to add/remove classes in the DOM?",
        "content": [
          {
            "type": "text",
            "value": "Use `element.classList.add()` to add and `element.classList.remove()` to remove classes."
          },
          {
            "type": "code",
            "value": "let element = document.getElementById(\"container\");\nelement.classList.add(\"active\");\nelement.classList.remove(\"active\");"
          }
        ]
      },
      {
        "description": "How to add event listeners in the DOM?",
        "content": [
          {
            "type": "text",
            "value": "Use `addEventListener()` to attach event handlers to elements."
          },
          {
            "type": "code",
            "value": "let button = document.getElementById(\"button\");\nbutton.addEventListener(\"click\", () => console.log(\"Clicked!\"));"
          }
        ]
      },
      {
        "description": "What is local storage in JavaScript?",
        "content": [
          {
            "type": "text",
            "value": "Local Storage stores key-value pairs with no expiration date, surviving browser restarts."
          },
          {
            "type": "code",
            "value": "localStorage.setItem(\"name\", \"John\");\nconsole.log(localStorage.getItem(\"name\")); // \"John\""
          }
        ]
      },
      {
        "description": "What is session storage in JavaScript?",
        "content": [
          {
            "type": "text",
            "value": "Session Storage stores key-value pairs but is cleared when the browser is closed."
          },
          {
            "type": "code",
            "value": "sessionStorage.setItem(\"name\", \"John\");\nconsole.log(sessionStorage.getItem(\"name\")); // \"John\""
          }
        ]
      },
      {
        "description": "What is JSON?",
        "content": [
          {
            "type": "text",
            "value": "JSON (JavaScript Object Notation) is a lightweight data format for exchanging structured data."
          },
          {
            "type": "code",
            "value": "let person = { name: \"John\", age: 30 };\nlet json = JSON.stringify(person);\nconsole.log(json); // '{\"name\":\"John\",\"age\":30}'\nlet obj = JSON.parse(json);\nconsole.log(obj.name); // \"John\""
          }
        ]
      },
      {
        "description": "What is a web API?",
        "content": [
          {
            "type": "text",
            "value": "A Web API allows web applications to communicate with external services."
          },
          {
            "type": "code",
            "value": "fetch(\"https://api.example.com/data\")\n    .then(response => response.json())\n    .then(data => console.log(data));"
          }
        ]
      },
      {
        "description": "What is CORS?",
        "content": [
          {
            "type": "text",
            "value": "Cross-Origin Resource Sharing (CORS) enables secure resource sharing between different domains."
          },
          {
            "type": "code",
            "value": "Access-Control-Allow-Origin: *\nAccess-Control-Allow-Methods: GET, POST\nAccess-Control-Allow-Headers: Content-Type"
          }
        ]
      },
      {
        "description": "What is a cookie in JavaScript?",
        "content": [
          {
            "type": "text",
            "value": "A cookie is a small piece of data stored in the browser that can be used for authentication and tracking."
          },
          {
            "type": "code",
            "value": "document.cookie = \"name=John Doe; expires=Thu, 18 Dec 2023 12:00:00 UTC\";"
          }
        ]
      },
      {
        "description": "Difference between deep copy and shallow copy?",
        "content": [
          {
            "type": "text",
            "value": "- **Shallow Copy**: Copies object references, so changes affect the original.\n- **Deep Copy**: Recursively copies all object properties, creating a completely independent copy."
          },
          {
            "type": "code",
            "value": "let obj = { name: \"John\", address: { city: \"New York\" } };\nlet shallow = { ...obj };\nlet deep = JSON.parse(JSON.stringify(obj));\n\nobj.address.city = \"Paris\";\nconsole.log(shallow.address.city); // \"Paris\"\nconsole.log(deep.address.city); // \"New York\""
          }
        ]
      },
      {
        "description": "How does hasOwnProperty() work in JavaScript?",
        "content": [
          {
            "type": "text",
            "value": "The `hasOwnProperty()` method checks if an object has a direct property (not inherited)."
          },
          {
            "type": "code",
            "value": "let person = { name: \"John\" };\nconsole.log(person.hasOwnProperty(\"name\")); // true\nconsole.log(person.hasOwnProperty(\"age\")); // false"
          }
        ]
      },
      {
        "description": "How do you prevent object mutation?",
        "content": [
          {
            "type": "text",
            "value": "Use `Object.freeze()` to prevent changes to an object."
          },
          {
            "type": "code",
            "value": "let person = { name: \"John\" };\nObject.freeze(person);\nperson.age = 30;\nconsole.log(person.age); // undefined"
          }
        ]
      },
      {
        "description": "Difference between Object.freeze() and Object.seal()?",
        "content": [
          {
            "type": "text",
            "value": "- **Object.freeze()**: Prevents adding, modifying, or removing properties.\n- **Object.seal()**: Prevents adding/removing properties but allows modifying existing ones."
          },
          {
            "type": "code",
            "value": "let person = { name: \"John\" };\nObject.freeze(person);\nObject.seal(person);\nperson.age = 30;\nconsole.log(person.age); // undefined\n\nperson.name = \"Doe\";\nconsole.log(person.name); // \"Doe\""
          }
        ]
      },
      {
        "description": "How can you clone an object?",
        "content": [
          {
            "type": "text",
            "value": "Use `Object.assign()` or the spread operator to create a copy of an object."
          },
          {
            "type": "code",
            "value": "let obj = { name: \"John\" };\nlet clone = Object.assign({}, obj);\nlet copy = { ...obj };"
          }
        ]
      },
      {
        "description": "What are different ways to create an array?",
        "content": [
          {
            "type": "text",
            "value": "- **Array Literal**: `let arr = [1, 2, 3];`\n- **Array Constructor**: `let arr = new Array(1, 2, 3);`\n- **Array.from()**: `let arr = Array.from([1, 2, 3]);`"
          },
          {
            "type": "code",
            "value": "let arr1 = [1, 2, 3];\nlet arr2 = new Array(1, 2, 3);\nlet arr3 = Array.from([1, 2, 3]);"
          }
        ]
      },
      {
        "description": "What is the difference between map and forEach?",
        "content": [
          {
            "type": "text",
            "value": "- **map()**: Returns a new array with transformed elements.\n- **forEach()**: Iterates through elements but does not return a new array."
          },
          {
            "type": "code",
            "value": "let numbers = [1, 2, 3];\nlet doubled = numbers.map(num => num * 2);\nnumbers.forEach(num => console.log(num));"
          }
        ]
      },
      {
        "description": "What is the difference between find and filter?",
        "content": [
          {
            "type": "text",
            "value": "- **find()**: Returns the first matching element.\n- **filter()**: Returns an array of all matching elements."
          },
          {
            "type": "code",
            "value": "let numbers = [1, 2, 3, 4, 5];\nlet found = numbers.find(num => num % 2 === 0);\nlet even = numbers.filter(num => num % 2 === 0);"
          }
        ]
      },
      {
        "description": "How can you remove duplicates from an array?",
        "content": [
          {
            "type": "text",
            "value": "- **Using Set**: `let unique = [...new Set(array)];`\n- **Using filter**: `let unique = array.filter((item, index) => array.indexOf(item) === index);`"
          },
          {
            "type": "code",
            "value": "let numbers = [1, 2, 2, 3, 4, 4, 5];\nlet unique = [...new Set(numbers)];\nconsole.log(unique); // [1, 2, 3, 4, 5]"
          }
        ]
      },
      {
        "description": "How can you flatten an array?",
        "content": [
          {
            "type": "text",
            "value": "Use `flat()` to convert nested arrays into a single array."
          },
          {
            "type": "code",
            "value": "let nested = [1, [2, 3], [4, [5]]];\nlet flat = nested.flat(Infinity);\nconsole.log(flat); // [1, 2, 3, 4, 5]"
          }
        ]
      },
      {
        "description": "What is the event loop in JavaScript?",
        "content": [
          {
            "type": "text",
            "value": "The event loop manages asynchronous operations to ensure non-blocking execution."
          },
          {
            "type": "code",
            "value": "console.log(\"Hello World!\");\nsetTimeout(() => console.log(\"How are you?\"), 1000);\nconsole.log(\"Goodbye!\");"
          }
        ]
      },
      {
        "description": "Difference between call stack and task queue?",
        "content": [
          {
            "type": "text",
            "value": "- **Call Stack**: Executes synchronous code in LIFO order.\n- **Task Queue**: Stores asynchronous tasks in FIFO order."
          },
          {
            "type": "code",
            "value": "console.log(\"Hello\");\nsetTimeout(() => console.log(\"World\"), 0);\nconsole.log(\"Goodbye\");"
          }
        ]
      },
      {
        "description": "Difference between microtask and macrotask?",
        "content": [
          {
            "type": "text",
            "value": "- **Microtask**: Executes after the current task but before the next task.\n- **Macrotask**: Executes after the current and next tasks."
          },
          {
            "type": "code",
            "value": "console.log(\"Hello\");\nPromise.resolve().then(() => console.log(\"World\"));\nconsole.log(\"Goodbye\");"
          }
        ]
      },
      {
        "description": "Difference between setTimeout and setInterval?",
        "content": [
          {
            "type": "text",
            "value": "- **setTimeout**: Runs a function once after a delay.\n- **setInterval**: Runs a function repeatedly at a set interval."
          },
          {
            "type": "code",
            "value": "setTimeout(() => console.log(\"Hello\"), 1000);\nsetInterval(() => console.log(\"World\"), 1000);"
          }
        ]
      },
      {
        "description": "What is callback hell, and how can it be avoided?",
        "content": [
          {
            "type": "text",
            "value": "Callback hell occurs when multiple nested callbacks make code unreadable.\n- **Avoid using inline callbacks**\n- **Use Promises or async/await**"
          },
          {
            "type": "code",
            "value": "// Callback Hell\nasyncFunc1(() => {\n    asyncFunc2(() => {\n        asyncFunc3(() => {\n            // More nested callbacks\n        });\n    });\n});\n\n// Avoiding Callback Hell with async/await\nasync function main() {\n    await asyncFunc1();\n    await asyncFunc2();\n    await asyncFunc3();\n}"
          }
        ]
      },
      {
        "description": "What happens if you don't handle a rejected Promise?",
        "content": [
          {
            "type": "text",
            "value": "An unhandled Promise rejection error occurs, which can crash the application in some environments."
          }
        ]
      },
      {
        "description": "Difference between Promise.all(), Promise.allSettled(), Promise.any(), and Promise.race()?",
        "content": [
          {
            "type": "text",
            "value": "- **Promise.all()**: Resolves when all promises resolve, rejects if one fails.\n- **Promise.allSettled()**: Resolves after all promises are settled (resolved or rejected).\n- **Promise.any()**: Resolves when any promise resolves, ignores failures.\n- **Promise.race()**: Resolves/rejects with the first settled promise."
          },
          {
            "type": "code",
            "value": "let promise1 = Promise.resolve(1);\nlet promise2 = Promise.reject(\"Error\");\n\nPromise.all([promise1, promise2])\n    .then(values => console.log(values))\n    .catch(error => console.log(error));"
          }
        ]
      },
      {
        "description": "Difference between async and defer attributes in a script tag?",
        "content": [
          {
            "type": "text",
            "value": "- **async**: Downloads script asynchronously and executes when ready.\n- **defer**: Downloads script asynchronously but executes after parsing."
          },
          {
            "type": "code",
            "value": "<script async src=\"script.js\"></script>\n<script defer src=\"script.js\"></script>"
          }
        ]
      },
      {
        "description": "Difference between innerText and textContent?",
        "content": [
          {
            "type": "text",
            "value": "- **innerText**: Returns only visible text (respects CSS styles).\n- **textContent**: Returns all text, even hidden."
          },
          {
            "type": "code",
            "value": "let element = document.getElementById(\"container\");\nconsole.log(element.innerText);\nconsole.log(element.textContent);"
          }
        ]
      },
      {
        "description": "How do you create a delay function using Promises?",
        "content": [
          {
            "type": "text",
            "value": "Use `setTimeout` within a Promise to create a delay function."
          },
          {
            "type": "code",
            "value": "function delay(ms) {\n    return new Promise(resolve => setTimeout(resolve, ms));\n}\n\ndelay(1000).then(() => console.log(\"Hello World!\"));"
          }
        ]
      },
      {
        "description": "What is the difference between Bubbling and Capturing in event propagation?",
        "content": [
          {
            "type": "text",
            "value": "- **Bubbling**: Events propagate from the target element to its parent elements.\n- **Capturing**: Events propagate from the parent elements down to the target."
          },
          {
            "type": "code",
            "value": "let parent = document.getElementById(\"parent\");\nlet child = document.getElementById(\"child\");\n\nparent.addEventListener(\"click\", () => console.log(\"Parent\"));\nchild.addEventListener(\"click\", () => console.log(\"Child\"));"
          }
        ]
      },
      {
        "description": "What is the difference between event.preventDefault() and event.stopPropagation()?",
        "content": [
          {
            "type": "text",
            "value": "- **preventDefault()**: Prevents the default action of an event.\n- **stopPropagation()**: Stops the event from bubbling or capturing."
          },
          {
            "type": "code",
            "value": "let link = document.getElementById(\"link\");\nlink.addEventListener(\"click\", event => {\n    event.preventDefault();\n    event.stopPropagation();\n});"
          }
        ]
      },
      {
        "description": "What is the difference between try, catch, and finally?",
        "content": [
          {
            "type": "text",
            "value": "- **try**: Contains code that might throw an error.\n- **catch**: Handles errors if they occur.\n- **finally**: Executes code after try/catch regardless of the outcome."
          },
          {
            "type": "code",
            "value": "try {\n    throw new Error(\"Error!\");\n} catch (error) {\n    console.log(error.message);\n} finally {\n    console.log(\"Execution completed\");\n}"
          }
        ]
      },
      {
        "description": "What is the difference between throw and return?",
        "content": [
          {
            "type": "text",
            "value": "- **throw**: Throws an exception, stopping further execution.\n- **return**: Exits a function and optionally returns a value."
          },
          {
            "type": "code",
            "value": "function divide(a, b) {\n    if (b === 0) {\n        throw new Error(\"Division by zero!\");\n    }\n    return a / b;\n}"
          }
        ]
      },
      {
        "description": "How do you handle asynchronous errors in JavaScript?",
        "content": [
          {
            "type": "text",
            "value": "Use try/catch with async/await or `.catch()` with Promises."
          },
          {
            "type": "code",
            "value": "async function fetchData() {\n    try {\n        let response = await fetch(\"https://api.example.com/data\");\n        let data = await response.json();\n        console.log(data);\n    } catch (error) {\n        console.log(error.message);\n    }\n}\n\nfetchData();"
          }
        ]
      },
      {
        "description": "What is a custom error in JavaScript?",
        "content": [
          {
            "type": "text",
            "value": "Custom errors are created by extending the `Error` class."
          },
          {
            "type": "code",
            "value": "class ValidationError extends Error {\n    constructor(message) {\n        super(message);\n        this.name = \"ValidationError\";\n    }\n}\n\nthrow new ValidationError(\"Invalid input!\");"
          }
        ]
      },
      {
        "description": "What are ES6 modules?",
        "content": [
          {
            "type": "text",
            "value": "ES6 modules allow code to be split into multiple files using `import` and `export`."
          },
          {
            "type": "code",
            "value": "// File: utils.js\nexport function sum(a, b) {\n    return a + b;\n}\n\n// File: main.js\nimport { sum } from \"./utils.js\";\nconsole.log(sum(1, 2));"
          }
        ]
      },
      {
        "description": "What is the difference between named and default exports?",
        "content": [
          {
            "type": "text",
            "value": "- **Named Exports**: Export multiple values by name.\n- **Default Export**: Export a single value as the default."
          },
          {
            "type": "code",
            "value": "// Named Exports\nexport function sum(a, b) {\n    return a + b;\n}\n\n// Default Export\nexport default function sum(a, b) {\n    return a + b;\n}"
          }
        ]
      },
      {
        "description": "How do you use a module in Node.js?",
        "content": [
          {
            "type": "text",
            "value": "Use `require()` to import CommonJS modules."
          },
          {
            "type": "code",
            "value": "// File: utils.js\nmodule.exports = { sum: (a, b) => a + b };\n\n// File: main.js\nconst utils = require(\"./utils.js\");\nconsole.log(utils.sum(1, 2));"
          }
        ]
      },
      {
        "description": "What is the DOM in JavaScript?",
        "content": [
          {
            "type": "text",
            "value": "The Document Object Model (DOM) represents the structure of a web page as objects for scripting."
          }
        ]
      },
      {
        "description": "How do you select elements in the DOM?",
        "content": [
          {
            "type": "text",
            "value": "- **getElementById**: Selects an element by ID.\n- **getElementsByClassName**: Selects elements by class name.\n- **getElementsByTagName**: Selects elements by tag name.\n- **querySelector**: Selects the first matching element.\n- **querySelectorAll**: Selects all matching elements."
          },
          {
            "type": "code",
            "value": "let container = document.getElementById(\"container\");\nlet paragraphs = document.getElementsByClassName(\"text\");\nlet first = document.querySelector(\".text\");\nlet all = document.querySelectorAll(\".text\");"
          }
        ]
      },
      {
        "description": "How do you create elements in the DOM?",
        "content": [
          {
            "type": "text",
            "value": "Use `document.createElement()` to create new elements dynamically."
          },
          {
            "type": "code",
            "value": "let div = document.createElement(\"div\");\ndiv.textContent = \"Hello World!\";\ndocument.body.appendChild(div);"
          }
        ]
      },
      {
        "description": "What is event delegation?",
        "content": [
          {
            "type": "text",
            "value": "Event delegation is a technique where a single event listener is used to handle events for multiple child elements."
          },
          {
            "type": "code",
            "value": "let list = document.getElementById(\"list\");\nlist.addEventListener(\"click\", event => {\n    if (event.target.tagName === \"LI\") {\n        console.log(event.target.textContent);\n    }\n});"
          }
        ]
      },
      {
        "description": "How do you manipulate the DOM using JavaScript?",
        "content": [
          {
            "type": "text",
            "value": "- **Create elements**: `document.createElement()`\n- **Add elements**: `parent.appendChild(child)`\n- **Remove elements**: `parent.removeChild(child)`\n- **Replace elements**: `parent.replaceChild(new, old)`"
          },
          {
            "type": "code",
            "value": "let div = document.createElement(\"div\");\ndiv.textContent = \"Hello World!\";\ndocument.body.appendChild(div);"
          }
        ]
      },
      {
        "description": "How does lazy loading work?",
        "content": [
          {
            "type": "text",
            "value": "Lazy loading defers loading non-essential resources until they are needed."
          },
          {
            "type": "code",
            "value": "<img src=\"image.jpg\" loading=\"lazy\">"
          }
        ]
      },
      {
        "description": "How can you improve JavaScript performance?",
        "content": [
          {
            "type": "text",
            "value": "- Minimize DOM manipulation.\n- Use event delegation.\n- Optimize loops.\n- Avoid memory leaks.\n- Use efficient algorithms."
          }
        ]
      },
      {
        "description": "How do you prevent XSS (Cross-Site Scripting)?",
        "content": [
          {
            "type": "text",
            "value": "Prevent XSS by sanitizing user input, escaping characters, and using Content Security Policy (CSP)."
          },
          {
            "type": "code",
            "value": "let userInput = \"<script>alert('XSS')</script>\";\nlet sanitized = userInput.replace(/</g, \"&lt;\").replace(/>/g, \"&gt;\");\nconsole.log(sanitized); // \"&lt;script&gt;alert('XSS')&lt;/script&gt;\""
          }
        ]
      },
      {
        "description": "How do you prevent CSRF (Cross-Site Request Forgery)?",
        "content": [
          {
            "type": "text",
            "value": "Prevent CSRF by using CSRF tokens, checking the referrer, and using SameSite cookies."
          },
          {
            "type": "code",
            "value": "let token = document.querySelector('meta[name=\"csrf-token\"]').content;\nfetch(\"https://api.example.com/data\", {\n    method: \"POST\",\n    headers: {\n        \"X-CSRF-Token\": token\n    }\n});"
          }
        ]
      },
      {
        "description": "What is Content Security Policy (CSP)?",
        "content": [
          {
            "type": "text",
            "value": "Content Security Policy (CSP) is a security feature that helps prevent XSS attacks by restricting the sources from which scripts, styles, and other resources can be loaded."
          }
        ]
      },
      {
        "description": "How can you secure JavaScript code?",
        "content": [
          {
            "type": "text",
            "value": "- Avoid using `eval()`.\n- Use strict mode (`'use strict'`).\n- Validate and sanitize user input."
          }
        ]
      },
      {
        "description": "How does JavaScript handle multithreading?",
        "content": [
          {
            "type": "text",
            "value": "JavaScript is single-threaded but handles asynchronous operations using callbacks, Promises, and `async/await`."
          }
        ]
      }
    ],
  },
  nodejs:
  {
    pages: [
      {
        "description": "What is Node.js, and how does it work?",
        "content": [
          {
            "type": "text",
            "value": "Node.js is a runtime environment for executing JavaScript outside the browser. It is built on the V8 engine and uses an event-driven, non-blocking I/O model."
          },
          {
            "type": "code",
            "value": "// Example of a simple Node.js server\nconst http = require('http');\nhttp.createServer((req, res) => {\n    res.writeHead(200, { 'Content-Type': 'text/plain' });\n    res.end('Hello, World!');\n}).listen(3000, () => console.log('Server running on port 3000'));"
          }
        ]
      },
      {
        "description": "What are the key features of Node.js?",
        "content": [
          {
            "type": "text",
            "value": "- **Asynchronous and event-driven**: Uses a non-blocking I/O model.\n- **Single-threaded**: Handles multiple requests using an event loop.\n- **High performance**: Powered by the V8 engine.\n- **Scalable**: Ideal for real-time applications.\n- **Rich ecosystem**: Includes npm, the largest package registry."
          }
        ]
      },
      {
        "description": "How is Node.js different from JavaScript in the browser?",
        "content": [
          {
            "type": "text",
            "value": "- **Execution Environment**: Node.js runs on a server, while JavaScript in the browser runs in a web page.\n- **APIs**: Node.js has built-in modules like `fs` and `http`, while browsers provide `DOM` and `Window`.\n- **Security**: Browsers enforce stricter security policies, while Node.js has full file system and network access."
          },
          {
            "type": "code",
            "value": "// Example: Using 'fs' module in Node.js\nconst fs = require('fs');\nfs.writeFileSync('test.txt', 'Hello Node.js');"
          }
        ]
      },
      {
        "description": "What is the V8 engine, and how does it work in Node.js?",
        "content": [
          {
            "type": "text",
            "value": "The V8 engine is a high-performance JavaScript engine developed by Google. It compiles JavaScript to machine code using Just-In-Time (JIT) compilation, making execution faster."
          },
          {
            "type": "code",
            "value": "// Example of V8 optimization\nfunction add(a, b) {\n    return a + b;\n}\nconsole.log(add(5, 10));"
          }
        ]
      },
      {
        "description": "Why is Node.js single-threaded, and how does it handle concurrency?",
        "content": [
          {
            "type": "text",
            "value": "Node.js uses a single-threaded event loop to handle multiple connections asynchronously. It achieves concurrency by delegating heavy tasks to worker threads using the libuv library."
          },
          {
            "type": "code",
            "value": "// Example: Handling multiple requests\nconst http = require('http');\nhttp.createServer((req, res) => {\n    setTimeout(() => res.end('Processed Request'), 1000);\n}).listen(3000);"
          }
        ]
      },
      {
        "description": "What is the event loop in Node.js, and how does it work?",
        "content": [
          {
            "type": "text",
            "value": "The event loop is a mechanism that processes asynchronous tasks in Node.js. It continuously checks the call stack, callback queue, and microtask queue."
          },
          {
            "type": "code",
            "value": "// Example demonstrating event loop behavior\nconsole.log('Start');\nsetTimeout(() => console.log('Timeout'), 0);\nPromise.resolve().then(() => console.log('Promise'));\nconsole.log('End');"
          }
        ]
      },
      {
        "description": "What is the difference between synchronous and asynchronous programming in Node.js?",
        "content": [
          {
            "type": "text",
            "value": "- **Synchronous**: Blocking, executes one operation at a time.\n- **Asynchronous**: Non-blocking, executes tasks in parallel using callbacks, promises, or async/await."
          },
          {
            "type": "code",
            "value": "// Synchronous Example\nconst fs = require('fs');\nlet data = fs.readFileSync('file.txt', 'utf8');\nconsole.log(data);\n\n// Asynchronous Example\nfs.readFile('file.txt', 'utf8', (err, data) => {\n    console.log(data);\n});"
          }
        ]
      },
      {
        "description": "What are CommonJS and ES6 modules in Node.js?",
        "content": [
          {
            "type": "text",
            "value": "CommonJS and ES6 modules are two module systems in Node.js.\n- **CommonJS**: The default module system in Node.js, using `require()` and `module.exports`.\n- **ES6 Modules**: Introduced in ES6, using `import` and `export` statements."
          },
          {
            "type": "code",
            "value": "// CommonJS\nconst fs = require('fs');\nmodule.exports = { readFile: fs.readFileSync };\n\n// ES6 Modules\nimport fs from 'fs';\nexport function readFile(file) { return fs.readFileSync(file); }"
          }
        ]
      },
      {
        "description": "How do you import and export modules in Node.js?",
        "content": [
          {
            "type": "text",
            "value": "In Node.js, you can import and export modules using either CommonJS (`require`) or ES6 Modules (`import`)."
          },
          {
            "type": "code",
            "value": "// CommonJS Export\nmodule.exports = { greet: () => console.log('Hello!') };\n\n// CommonJS Import\nconst utils = require('./utils');\nutils.greet();\n\n// ES6 Modules Export\nexport function greet() { console.log('Hello!'); }\n\n// ES6 Modules Import\nimport { greet } from './utils.js';\ngreet();"
          }
        ]
      },
      {
        "description": "What is package.json, and why is it important?",
        "content": [
          {
            "type": "text",
            "value": "`package.json` is a file that contains metadata about a Node.js project, including dependencies, scripts, and configurations. It helps manage project settings and dependencies efficiently."
          },
          {
            "type": "code",
            "value": "// Example package.json\n{\n  \"name\": \"my-app\",\n  \"version\": \"1.0.0\",\n  \"dependencies\": {\n    \"express\": \"^4.17.1\"\n  },\n  \"scripts\": {\n    \"start\": \"node index.js\"\n  }\n}"
          }
        ]
      },
      {
        "description": "What is the difference between dependencies, devDependencies, and peerDependencies?",
        "content": [
          {
            "type": "text",
            "value": "- **dependencies**: Required for production (`npm install package`).\n- **devDependencies**: Used only in development (`npm install package --save-dev`).\n- **peerDependencies**: Expected to be installed by the user, useful for plugins."
          },
          {
            "type": "code",
            "value": "// Example in package.json\n{\n  \"dependencies\": { \"express\": \"^4.17.1\" },\n  \"devDependencies\": { \"jest\": \"^27.0.6\" },\n  \"peerDependencies\": { \"react\": \"^17.0.0\" }\n}"
          }
        ]
      },
      {
        "description": "What is NPX, and how does it differ from NPM?",
        "content": [
          {
            "type": "text",
            "value": "NPX is a package runner that allows executing Node.js packages without installing them globally. Unlike NPM, which installs packages, NPX runs them directly."
          },
          {
            "type": "code",
            "value": "// Example: Running create-react-app without installing\nnpx create-react-app my-app"
          }
        ]
      },
      {
        "description": "How do you read and write files in Node.js?",
        "content": [
          {
            "type": "text",
            "value": "Use the `fs` module in Node.js to read and write files."
          },
          {
            "type": "code",
            "value": "// Reading a file\nconst fs = require('fs');\nfs.readFile('file.txt', 'utf8', (err, data) => {\n    console.log(data);\n});\n\n// Writing to a file\nfs.writeFile('file.txt', 'Hello, Node.js!', err => {\n    if (err) throw err;\n});"
          }
        ]
      },
      {
        "description": "What is the difference between fs.readFile() and fs.createReadStream()?",
        "content": [
          {
            "type": "text",
            "value": "- **fs.readFile()**: Reads the entire file into memory, useful for small files.\n- **fs.createReadStream()**: Reads data in chunks, useful for large files."
          },
          {
            "type": "code",
            "value": "// Using fs.readFile()\nfs.readFile('file.txt', 'utf8', (err, data) => console.log(data));\n\n// Using fs.createReadStream()\nfs.createReadStream('file.txt').on('data', chunk => console.log(chunk.toString()));"
          }
        ]
      },
      {
        "description": "What are streams in Node.js, and what are their types?",
        "content": [
          {
            "type": "text",
            "value": "Streams are used to handle large amounts of data efficiently. Types of streams:\n- **Readable**: Read data (e.g., `fs.createReadStream`).\n- **Writable**: Write data (e.g., `fs.createWriteStream`).\n- **Duplex**: Read and write data (e.g., sockets).\n- **Transform**: Modify data (e.g., compression)."
          }
        ]
      },
      {
        "description": "How do you handle large files efficiently in Node.js?",
        "content": [
          {
            "type": "text",
            "value": "Use streams to process large files without loading them into memory."
          },
          {
            "type": "code",
            "value": "// Efficiently reading a large file\nconst fs = require('fs');\nfs.createReadStream('largeFile.txt')\n  .on('data', chunk => console.log('Processing chunk...'))\n  .on('end', () => console.log('Done processing file'));"
          }
        ]
      },
      {
        "description": "What is the EventEmitter in Node.js, and how does it work?",
        "content": [
          {
            "type": "text",
            "value": "EventEmitter is a built-in module in Node.js that allows handling custom events asynchronously."
          },
          {
            "type": "code",
            "value": "const EventEmitter = require('events');\nconst emitter = new EventEmitter();\nemitter.on('message', msg => console.log(msg));\nemitter.emit('message', 'Hello EventEmitter!');"
          }
        ]
      },
      {
        "description": "How do you create and use custom events in Node.js?",
        "content": [
          {
            "type": "text",
            "value": "Use the `events` module to create and emit custom events."
          },
          {
            "type": "code",
            "value": "const EventEmitter = require('events');\nclass MyEmitter extends EventEmitter {}\nconst myEmitter = new MyEmitter();\nmyEmitter.on('greet', () => console.log('Hello!'));\nmyEmitter.emit('greet');"
          }
        ]
      },
      {
        "description": "What is the difference between process.nextTick() and setImmediate()?",
        "content": [
          {
            "type": "text",
            "value": "- **process.nextTick()**: Executes callbacks immediately after the current operation, before I/O events.\n- **setImmediate()**: Executes callbacks after I/O events, in the next event loop iteration."
          },
          {
            "type": "code",
            "value": "console.log('Start');\nprocess.nextTick(() => console.log('nextTick'));\nsetImmediate(() => console.log('setImmediate'));\nconsole.log('End');"
          }
        ]
      },
      {
        "description": "How do you create a simple HTTP server in Node.js?",
        "content": [
          {
            "type": "text",
            "value": "Use the built-in `http` module to create a simple HTTP server in Node.js."
          },
          {
            "type": "code",
            "value": "const http = require('http');\nconst server = http.createServer((req, res) => {\n  res.writeHead(200, { 'Content-Type': 'text/plain' });\n  res.end('Hello, World!');\n});\nserver.listen(3000, () => console.log('Server running on port 3000'));"
          }
        ]
      },
      {
        "description": "What is Express.js, and why is it used?",
        "content": [
          {
            "type": "text",
            "value": "Express.js is a lightweight web framework for Node.js used for building APIs and web applications easily."
          },
          {
            "type": "code",
            "value": "const express = require('express');\nconst app = express();\napp.get('/', (req, res) => res.send('Hello, Express!'));\napp.listen(3000, () => console.log('Server running on port 3000'));"
          }
        ]
      },
      {
        "description": "What are middlewares in Express.js?",
        "content": [
          {
            "type": "text",
            "value": "Middlewares are functions in Express.js that process requests before reaching the final route handler."
          },
          {
            "type": "code",
            "value": "app.use((req, res, next) => {\n  console.log('Request received');\n  next();\n});"
          }
        ]
      },
      {
        "description": "What is the difference between app.use() and app.get()?",
        "content": [
          {
            "type": "text",
            "value": "- `app.use()`: Used for middleware that applies to all routes.\n- `app.get()`: Handles only GET requests for a specific route."
          },
          {
            "type": "code",
            "value": "app.use((req, res, next) => console.log('Middleware executed'));\napp.get('/', (req, res) => res.send('Hello, World!'));"
          }
        ]
      },
      {
        "description": "How do you handle errors in Express.js?",
        "content": [
          {
            "type": "text",
            "value": "Use error-handling middleware by passing `err` as the first parameter."
          },
          {
            "type": "code",
            "value": "app.use((err, req, res, next) => {\n  console.error(err.message);\n  res.status(500).send('Server Error');\n});"
          }
        ]
      },
      {
        "description": "What are callbacks, and what is callback hell?",
        "content": [
          {
            "type": "text",
            "value": "Callbacks are functions passed as arguments to handle asynchronous operations. Callback hell occurs when multiple nested callbacks make code hard to read."
          },
          {
            "type": "code",
            "value": "fs.readFile('file.txt', (err, data) => {\n  if (err) return console.error(err);\n  console.log(data);\n});"
          }
        ]
      },
      {
        "description": "What are Promises, and how do they work?",
        "content": [
          {
            "type": "text",
            "value": "Promises handle asynchronous operations and avoid callback hell using `.then()` and `.catch()`."
          },
          {
            "type": "code",
            "value": "new Promise((resolve, reject) => {\n  setTimeout(() => resolve('Done!'), 1000);\n}).then(console.log);"
          }
        ]
      },
      {
        "description": "What is async/await, and how is it different from Promises?",
        "content": [
          {
            "type": "text",
            "value": "`async/await` simplifies handling Promises by making code look synchronous."
          },
          {
            "type": "code",
            "value": "async function fetchData() {\n  let response = await fetch('https://api.example.com/data');\n  let data = await response.json();\n  console.log(data);\n}"
          }
        ]
      },
      {
        "description": "What is the difference between Promise.all() and Promise.race()?",
        "content": [
          {
            "type": "text",
            "value": "- `Promise.all()`: Waits for all promises to resolve.\n- `Promise.race()`: Resolves as soon as the first promise resolves."
          },
          {
            "type": "code",
            "value": "Promise.all([promise1, promise2]).then(console.log);\nPromise.race([promise1, promise2]).then(console.log);"
          }
        ]
      },
      {
        "description": "How do you connect Node.js with MongoDB?",
        "content": [
          {
            "type": "text",
            "value": "Use the `mongodb` or `mongoose` package to connect Node.js to MongoDB."
          },
          {
            "type": "code",
            "value": "const mongoose = require('mongoose');\nmongoose.connect('mongodb://localhost:27017/mydb', { useNewUrlParser: true, useUnifiedTopology: true });"
          }
        ]
      },
      {
        "description": "What is Mongoose, and why is it used?",
        "content": [
          {
            "type": "text",
            "value": "Mongoose is an ODM (Object Data Modeling) library for MongoDB in Node.js."
          }
        ]
      },
      {
        "description": "What is the difference between SQL and NoSQL databases?",
        "content": [
          {
            "type": "text",
            "value": "- **SQL**: Structured, uses tables and schemas.\n- **NoSQL**: Flexible, uses key-value, document, or graph storage."
          }
        ]
      },
      {
        "description": "How do you handle transactions in MySQL or MongoDB using Node.js?",
        "content": [
          {
            "type": "text",
            "value": "Use transactions to ensure data consistency in databases."
          },
          {
            "type": "code",
            "value": "// MongoDB Transaction\nconst session = await mongoose.startSession();\nsession.startTransaction();\ntry {\n  await User.create([{ name: 'John' }], { session });\n  await session.commitTransaction();\n} catch (err) {\n  await session.abortTransaction();\n}"
          }
        ]
      },
      {
        "description": "What is JWT, and how does it work?",
        "content": [
          {
            "type": "text",
            "value": "JWT (JSON Web Token) is used for authentication by encoding user data into a token."
          },
          {
            "type": "code",
            "value": "const jwt = require('jsonwebtoken');\nconst token = jwt.sign({ userId: 123 }, 'secret', { expiresIn: '1h' });"
          }
        ]
      },
      {
        "description": "How do you store passwords securely in Node.js?",
        "content": [
          {
            "type": "text",
            "value": "Use `bcrypt` to hash passwords before storing them in a database."
          },
          {
            "type": "code",
            "value": "const bcrypt = require('bcrypt');\nconst hashedPassword = await bcrypt.hash('password123', 10);"
          }
        ]
      },
      {
        "description": "What is the purpose of bcrypt in authentication?",
        "content": [
          {
            "type": "text",
            "value": "`bcrypt` is used to hash passwords securely and compare them during login."
          }
        ]
      },
      {
        "description": "How do you prevent SQL injection in Node.js?",
        "content": [
          {
            "type": "text",
            "value": "Use parameterized queries to prevent SQL injection."
          },
          {
            "type": "code",
            "value": "const db = require('mysql');\ndb.query('SELECT * FROM users WHERE username = ?', [username], (err, results) => {\n  console.log(results);\n});"
          }
        ]
      },
      {
        "description": "What is clustering in Node.js, and how does it improve performance?",
        "content": [
          {
            "type": "text",
            "value": "Clustering allows Node.js to utilize multiple CPU cores by creating worker processes that share the same server port."
          },
          {
            "type": "code",
            "value": "const cluster = require('cluster');\nconst http = require('http');\nconst os = require('os');\nif (cluster.isMaster) {\n  os.cpus().forEach(() => cluster.fork());\n} else {\n  http.createServer((req, res) => res.end('Hello')).listen(3000);\n}"
          }
        ]
      },
      {
        "description": "How do you use caching to improve performance in Node.js?",
        "content": [
          {
            "type": "text",
            "value": "Caching stores frequently accessed data to reduce load time and database queries."
          },
          {
            "type": "code",
            "value": "const cache = new Map();\nfunction getData(key) {\n  if (cache.has(key)) return cache.get(key);\n  let data = fetchFromDatabase(key);\n  cache.set(key, data);\n  return data;\n}"
          }
        ]
      },
      {
        "description": "What is Redis, and how does it work with Node.js?",
        "content": [
          {
            "type": "text",
            "value": "Redis is an in-memory key-value store used for caching, session storage, and real-time applications in Node.js."
          },
          {
            "type": "code",
            "value": "const redis = require('redis');\nconst client = redis.createClient();\nclient.set('key', 'value');\nclient.get('key', (err, reply) => console.log(reply));"
          }
        ]
      },
      {
        "description": "How do you monitor the performance of a Node.js application?",
        "content": [
          {
            "type": "text",
            "value": "Use monitoring tools like `pm2`, `New Relic`, or `node --inspect` for profiling."
          }
        ]
      },
      {
        "description": "What are the different testing frameworks available for Node.js?",
        "content": [
          {
            "type": "text",
            "value": "Popular testing frameworks include Jest, Mocha, Chai, and Jasmine."
          }
        ]
      },
      {
        "description": "What is Jest, and how is it used for testing?",
        "content": [
          {
            "type": "text",
            "value": "Jest is a JavaScript testing framework used for unit and integration testing."
          },
          {
            "type": "code",
            "value": "test('adds 1 + 2 to equal 3', () => {\n  expect(1 + 2).toBe(3);\n});"
          }
        ]
      },
      {
        "description": "How do you perform API testing in Node.js?",
        "content": [
          {
            "type": "text",
            "value": "Use tools like Postman or frameworks like Jest and Supertest for API testing."
          },
          {
            "type": "code",
            "value": "const request = require('supertest');\ntest('GET /', async () => {\n  const res = await request(app).get('/');\n  expect(res.statusCode).toBe(200);\n});"
          }
        ]
      },
      {
        "description": "What is the difference between unit tests and integration tests?",
        "content": [
          {
            "type": "text",
            "value": "- **Unit Tests**: Test individual functions.\n- **Integration Tests**: Test multiple modules working together."
          }
        ]
      },
      {
        "description": "What are worker threads in Node.js?",
        "content": [
          {
            "type": "text",
            "value": "Worker threads allow parallel execution of JavaScript code in Node.js."
          },
          {
            "type": "code",
            "value": "const { Worker } = require('worker_threads');\nconst worker = new Worker('./worker.js');"
          }
        ]
      },
      {
        "description": "What is the difference between microtasks and macrotasks in Node.js?",
        "content": [
          {
            "type": "text",
            "value": "- **Microtasks**: Executed before macrotasks (e.g., `process.nextTick`, Promises).\n- **Macrotasks**: Scheduled in the event loop (e.g., `setTimeout`, `setImmediate`)."
          }
        ]
      },
      {
        "description": "How does Node.js handle memory management?",
        "content": [
          {
            "type": "text",
            "value": "Node.js uses the V8 garbage collector to automatically manage memory allocation and deallocation."
          }
        ]
      },
      {
        "description": "What is WebSockets, and how do you use them in Node.js?",
        "content": [
          {
            "type": "text",
            "value": "WebSockets allow real-time, bidirectional communication between the server and client."
          },
          {
            "type": "code",
            "value": "const WebSocket = require('ws');\nconst wss = new WebSocket.Server({ port: 8080 });\nwss.on('connection', ws => ws.send('Hello, WebSocket!'));"
          }
        ]
      },
      {
        "description": "What is the difference between fork, spawn, and exec in Node.js?",
        "content": [
          {
            "type": "text",
            "value": "- **fork**: Creates a new Node.js process that shares communication with the parent.\n- **spawn**: Executes a new process with streams.\n- **exec**: Executes a shell command and buffers output."
          }
        ]
      },
      {
        "description": "How do you deploy a Node.js application in production?",
        "content": [
          {
            "type": "text",
            "value": "Use PM2, Docker, or cloud services like AWS, Heroku, or DigitalOcean."
          },
          {
            "type": "code",
            "value": "pm2 start app.js"
          }
        ]
      }
    ],
  },
  typescript:
  {
    pages: [
    ],
  },
  database:
  {
    pages: [

      {
        "description": "What is MySQL, and why is it used?",
        "content": [
          {
            "type": "text",
            "value": "MySQL is an open-source relational database management system (RDBMS) used for storing, managing, and retrieving structured data efficiently."
          }
        ]
      },
      {
        "description": "What are the differences between SQL and NoSQL databases?",
        "content": [
          {
            "type": "text",
            "value": "- **SQL**: Structured, uses tables, enforces schema (e.g., MySQL, PostgreSQL).\n- **NoSQL**: Schema-less, stores data in JSON, key-value, or graph format (e.g., MongoDB)."
          }
        ]
      },
      {
        "description": "What are the ACID properties in MySQL?",
        "content": [
          {
            "type": "text",
            "value": "ACID (Atomicity, Consistency, Isolation, Durability) ensures reliable transactions in MySQL."
          }
        ]
      },
      {
        "description": "What is a primary key, and why is it important?",
        "content": [
          {
            "type": "text",
            "value": "A primary key uniquely identifies each row in a table and ensures data integrity."
          }
        ]
      },
      {
        "description": "What is a foreign key, and how does it work?",
        "content": [
          {
            "type": "text",
            "value": "A foreign key links one table to another, enforcing referential integrity."
          }
        ]
      },
      {
        "description": "How do you create a database and a table in MySQL?",
        "content": [
          {
            "type": "code",
            "value": "CREATE DATABASE mydb;\nUSE mydb;\nCREATE TABLE users (id INT PRIMARY KEY, name VARCHAR(50));"
          }
        ]
      },
      {
        "description": "What is the difference between CHAR and VARCHAR?",
        "content": [
          {
            "type": "text",
            "value": "- **CHAR**: Fixed-length string storage.\n- **VARCHAR**: Variable-length string storage."
          }
        ]
      },
      {
        "description": "What is the difference between DELETE, TRUNCATE, and DROP?",
        "content": [
          {
            "type": "text",
            "value": "- **DELETE**: Removes specific rows.\n- **TRUNCATE**: Removes all rows but keeps the structure.\n- **DROP**: Deletes the entire table."
          }
        ]
      },
      {
        "description": "How do you alter an existing table in MySQL?",
        "content": [
          {
            "type": "code",
            "value": "ALTER TABLE users ADD COLUMN age INT;"
          }
        ]
      },
      {
        "description": "How do you copy a table structure without copying data?",
        "content": [
          {
            "type": "code",
            "value": "CREATE TABLE new_table LIKE old_table;"
          }
        ]
      },
      {
        "description": "How do you retrieve all records from a table?",
        "content": [
          {
            "type": "code",
            "value": "SELECT * FROM users;"
          }
        ]
      },
      {
        "description": "What is the difference between WHERE and HAVING?",
        "content": [
          {
            "type": "text",
            "value": "- **WHERE**: Filters rows before aggregation.\n- **HAVING**: Filters rows after aggregation."
          }
        ]
      },
      {
        "description": "How does GROUP BY work in MySQL?",
        "content": [
          {
            "type": "code",
            "value": "SELECT department, COUNT(*) FROM employees GROUP BY department;"
          }
        ]
      },
      {
        "description": "What are the different types of joins in MySQL?",
        "content": [
          {
            "type": "text",
            "value": "- **INNER JOIN**: Matches rows from both tables.\n- **LEFT JOIN**: Includes all left table rows.\n- **RIGHT JOIN**: Includes all right table rows.\n- **FULL JOIN**: Includes all rows from both tables."
          }
        ]
      },
      {
        "description": "What is the difference between INNER JOIN, LEFT JOIN, and RIGHT JOIN?",
        "content": [
          {
            "type": "text",
            "value": "- **INNER JOIN**: Returns matching rows from both tables.\n- **LEFT JOIN**: Returns all rows from the left table and matching rows from the right.\n- **RIGHT JOIN**: Returns all rows from the right table and matching rows from the left."
          }
        ]
      },
      {
        "description": "What is an index, and how does it improve performance?",
        "content": [
          {
            "type": "text",
            "value": "An index speeds up queries by allowing faster lookups in a database."
          }
        ]
      },
      {
        "description": "What is the difference between a clustered and non-clustered index?",
        "content": [
          {
            "type": "text",
            "value": "- **Clustered Index**: Sorts and stores data rows physically.\n- **Non-clustered Index**: Stores only a reference to data rows."
          }
        ]
      },
      {
        "description": "How do you create and remove an index in MySQL?",
        "content": [
          {
            "type": "code",
            "value": "CREATE INDEX idx_name ON users(name);\nDROP INDEX idx_name ON users;"
          }
        ]
      },
      {
        "description": "How do you optimize slow queries in MySQL?",
        "content": [
          {
            "type": "text",
            "value": "- Use indexes.\n- Avoid SELECT *.\n- Optimize joins.\n- Use EXPLAIN to analyze queries."
          }
        ]
      },
      {
        "description": "What is the EXPLAIN command, and how is it used?",
        "content": [
          {
            "type": "code",
            "value": "EXPLAIN SELECT * FROM users WHERE age > 30;"
          }
        ]
      },
      {
        "description": "What is a transaction, and how do you use it in MySQL?",
        "content": [
          {
            "type": "code",
            "value": "START TRANSACTION;\nUPDATE accounts SET balance = balance - 100 WHERE id = 1;\nUPDATE accounts SET balance = balance + 100 WHERE id = 2;\nCOMMIT;"
          }
        ]
      },
      {
        "description": "What is the difference between COMMIT and ROLLBACK?",
        "content": [
          {
            "type": "text",
            "value": "- **COMMIT**: Saves all changes.\n- **ROLLBACK**: Undoes all changes in the transaction."
          }
        ]
      },
      {
        "description": "What are isolation levels in MySQL transactions?",
        "content": [
          {
            "type": "text",
            "value": "- **Read Uncommitted**: Allows dirty reads.\n- **Read Committed**: Prevents dirty reads.\n- **Repeatable Read**: Prevents non-repeatable reads.\n- **Serializable**: Prevents all concurrency issues."
          }
        ]
      },
      {
        "description": "What is a deadlock, and how can you prevent it?",
        "content": [
          {
            "type": "text",
            "value": "A deadlock occurs when two transactions block each other. Prevention methods include proper indexing, locking strategies, and timeout settings."
          }
        ]
      },
      {
        "description": "What is MySQL replication, and how does it work?",
        "content": [
          {
            "type": "text",
            "value": "MySQL replication synchronizes data between a primary and one or more secondary databases."
          },
          {
            "type": "code",
            "value": "CHANGE MASTER TO MASTER_HOST='master_host', MASTER_USER='replica_user', MASTER_PASSWORD='password';"
          }
        ]
      },
      {
        "description": "What is MongoDB, and how does it differ from MySQL?",
        "content": [
          {
            "type": "text",
            "value": "MongoDB is a NoSQL database that stores data in flexible JSON-like documents, while MySQL is a relational database that uses structured tables."
          }
        ]
      },
      {
        "description": "What are the advantages of using MongoDB over a relational database?",
        "content": [
          {
            "type": "text",
            "value": "- Schema-less design\n- Horizontal scaling\n- High performance with large datasets\n- Easier handling of unstructured data"
          }
        ]
      },
      {
        "description": "What is BSON, and how is it different from JSON?",
        "content": [
          {
            "type": "text",
            "value": "BSON (Binary JSON) is a binary format used by MongoDB that supports additional data types like Date and ObjectId."
          }
        ]
      },
      {
        "description": "What are collections and documents in MongoDB?",
        "content": [
          {
            "type": "text",
            "value": "A **collection** is a group of documents, similar to a table in SQL. A **document** is a JSON-like object containing key-value pairs."
          }
        ]
      },
      {
        "description": "What are the different data types supported in MongoDB?",
        "content": [
          {
            "type": "text",
            "value": "MongoDB supports String, Number, Boolean, Array, Object, Date, ObjectId, Binary Data, and more."
          }
        ]
      },
      {
        "description": "How do you insert a document into a MongoDB collection?",
        "content": [
          {
            "type": "code",
            "value": "db.users.insertOne({ name: 'John Doe', age: 30 });"
          }
        ]
      },
      {
        "description": "How do you update a document in MongoDB?",
        "content": [
          {
            "type": "code",
            "value": "db.users.updateOne({ name: 'John Doe' }, { $set: { age: 31 } });"
          }
        ]
      },
      {
        "description": "How do you delete documents from a MongoDB collection?",
        "content": [
          {
            "type": "code",
            "value": "db.users.deleteOne({ name: 'John Doe' });"
          }
        ]
      },
      {
        "description": "How do you retrieve documents using find()?",
        "content": [
          {
            "type": "code",
            "value": "db.users.find({ age: { $gt: 25 } });"
          }
        ]
      },
      {
        "description": "What is the difference between findOne() and find()?",
        "content": [
          {
            "type": "text",
            "value": "- **findOne()** returns a single document.\n- **find()** returns multiple documents as a cursor."
          }
        ]
      },
      {
        "description": "What are some common MongoDB query operators ($eq, $gt, $lt, $in, $nin)?",
        "content": [
          {
            "type": "text",
            "value": "- **$eq**: Matches values equal to a specified value.\n- **$gt**: Matches values greater than a specified value.\n- **$lt**: Matches values less than a specified value.\n- **$in**: Matches any value in an array.\n- **$nin**: Matches values not in an array."
          }
        ]
      },
      {
        "description": "How do you search for text using $regex in MongoDB?",
        "content": [
          {
            "type": "code",
            "value": "db.users.find({ name: { $regex: '^J', $options: 'i' } });"
          }
        ]
      },
      {
        "description": "What is $exists, and how is it used in queries?",
        "content": [
          {
            "type": "code",
            "value": "db.users.find({ email: { $exists: true } });"
          }
        ]
      },
      {
        "description": "How do you use the $or operator in MongoDB queries?",
        "content": [
          {
            "type": "code",
            "value": "db.users.find({ $or: [{ age: { $gt: 30 } }, { name: 'John' }] });"
          }
        ]
      },
      {
        "description": "What is the difference between $push, $addToSet, and $pull?",
        "content": [
          {
            "type": "text",
            "value": "- **$push**: Adds an element to an array.\n- **$addToSet**: Adds an element only if it does not exist.\n- **$pull**: Removes an element from an array."
          }
        ]
      },
      {
        "description": "What is an index in MongoDB, and how does it improve query performance?",
        "content": [
          {
            "type": "text",
            "value": "Indexes speed up queries by allowing MongoDB to find data more efficiently."
          }
        ]
      },
      {
        "description": "How do you create and remove an index in MongoDB?",
        "content": [
          {
            "type": "code",
            "value": "db.users.createIndex({ name: 1 });\ndb.users.dropIndex({ name: 1 });"
          }
        ]
      },
      {
        "description": "What are compound indexes, and how are they useful?",
        "content": [
          {
            "type": "text",
            "value": "A compound index includes multiple fields to improve query performance on complex queries."
          }
        ]
      },
      {
        "description": "How does MongoDB handle large datasets efficiently?",
        "content": [
          {
            "type": "text",
            "value": "MongoDB uses indexes, sharding, and replication to manage large datasets efficiently."
          }
        ]
      },
      {
        "description": "How do you use explain() to analyze query performance?",
        "content": [
          {
            "type": "code",
            "value": "db.users.find({ age: { $gt: 30 } }).explain('executionStats');"
          }
        ]
      },
      {
        "description": "What is replication in MongoDB, and why is it important?",
        "content": [
          {
            "type": "text",
            "value": "Replication creates copies of data across multiple servers for fault tolerance and high availability."
          }
        ]
      },
      {
        "description": "What is sharding in MongoDB, and when should you use it?",
        "content": [
          {
            "type": "text",
            "value": "Sharding distributes data across multiple machines to handle large-scale datasets and high traffic."
          }
        ]
      },
      {
        "description": "What is a shard key, and how is it selected?",
        "content": [
          {
            "type": "text",
            "value": "A shard key determines how data is distributed in a sharded cluster. It should be chosen based on query patterns."
          }
        ]
      },
      {
        "description": "How do MongoDB transactions work across multiple documents?",
        "content": [
          {
            "type": "code",
            "value": "const session = db.getMongo().startSession();\nsession.startTransaction();\ndb.users.updateOne({ _id: 1 }, { $set: { balance: 500 } });\ndb.accounts.updateOne({ _id: 2 }, { $set: { balance: 300 } });\nsession.commitTransaction();"
          }
        ]
      },
      {
        "description": "How do you monitor MongoDB performance?",
        "content": [
          {
            "type": "text",
            "value": "Use MongoDB tools like **mongostat**, **mongotop**, and **db.serverStatus()** to monitor performance."
          }
        ]
      }


    ],
  },
};