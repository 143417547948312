import React from "react";
import { notebookData } from "../data/notebookData";

const NoteSidebar = ({ selectedLanguage, setSelectedLanguage, setSelectedPage }) => {
  return (
    <div style={styles.sidebar}>
      <h2 style={styles.heading}>Languages</h2>
      <div style={styles.buttonContainer}>
        <button style={styles.button} onClick={() => setSelectedLanguage("python")}>Python</button>
        <button style={styles.button} onClick={() => setSelectedLanguage("javascript")}>JavaScript</button>
        <button style={styles.button} onClick={() => setSelectedLanguage("nodejs")}>Node.js</button>
        <button style={styles.button} onClick={() => setSelectedLanguage("typescript")}>TypeScript</button>
        <button style={styles.button} onClick={() => setSelectedLanguage("database")}>Database</button>
        
      </div>

      <h2 style={styles.heading}>Notes</h2>
      {notebookData[selectedLanguage].pages.map((page, index) => (
        <div key={index} style={styles.note} onClick={() => setSelectedPage(index)}>
          <h3 style={styles.noteTitle}>{page.description}</h3>
        </div>
      ))}
    </div>
  );
};

const styles = {
  sidebar: {
    width: "250px",
    backgroundColor: "#1e1e1e",
    padding: "20px",
    borderRight: "1px solid #333",
    overflowY: "auto",
    color: "#fff",
  },
  heading: {
    color: "#00e676",
    marginBottom: "10px",
  },
  buttonContainer: {
    marginBottom: "20px",
  },
  button: {
    display: "block",
    width: "100%",
    padding: "10px",
    marginBottom: "10px",
    backgroundColor: "#00e676",
    color: "#1e1e1e",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    textAlign: "left",
    transition: "background-color 0.3s",
  },
  buttonHover: {
    backgroundColor: "#00c853",
  },
  note: {
    marginBottom: "20px",
    cursor: "pointer",
    transition: "color 0.3s",
  },
  noteTitle: {
    color: "#fff",
    marginBottom: "10px",
  },
};

export default NoteSidebar;