import React from 'react';
import './Projects.css';
import projectImage from '../assets/profile.jpg'; // Add an image for projects
import phnkgif from '../assets/phnkapp.gif';
import amritpexgif from '../assets/amritpex2023.gif';
import tweencraftgif from '../assets/tweencraft.gif';

const Projects = () => {
    const companyProjects = [
        {
            name: 'Phnk Networks Pvt Ltd',
            description: 'Comprehensive App, Website, and Server Development',
            image: phnkgif,
            role: 'Lead Developer',
            technologies: ['Node.js', 'React', 'AWS', 'MySQL'],
            challenges: [
                'Ensuring low latency and high reliability in message delivery.',
                'Ensuring secure and smooth payment transactions.',
                'Implementing complex AI algorithms and ensuring real-time performance.',
                'Ensuring the platform can handle high traffic and scale efficiently.'
            ],
            impact: [
                'Improved user communication and engagement, leading to a 50% increase in active users.',
                'Increased in-app purchases by 25% and improved user satisfaction.',
                'Enhanced user experience and interaction, resulting in a 30% increase in user retention.',
                'Improved platform scalability and performance, supporting a growing user base.'
            ],
            links: [
                { name: 'Website', link: 'https://phnk.in' },
                { name: 'App Store', link: 'https://apps.apple.com/in/app/phnk/id6739332083' },
                { name: 'Play Store', link: 'https://play.google.com/store/apps/details?id=com.phnk' }
            ]
        },
        {
            name: 'AMRITPEX 2023',
            description: 'Comprehensive App, Website, and Server Development',
            image: amritpexgif,
            role: 'Full Stack Developer',
            technologies: ['Node.js', 'React', 'MongoDB', 'AWS'],
            challenges: [
                'Ensuring a seamless user experience across multiple features and maintaining app performance.',
                'Ensuring data security and handling concurrent user requests efficiently.',
                'Managing large volumes of data and ensuring quick data retrieval.'
            ],
            impact: [
                'Increased user engagement and app downloads by 35%.',
                'Improved data security and reduced server response times by 40%.',
                'Enhanced database performance and scalability, supporting a growing user base.'
            ],
        },
        {
            name: 'Kalpkriti Pvt. Ltd',
            description: 'Website Redesign and API Development',
            image: tweencraftgif,
            role: 'Software Engineer',
            technologies: ['Node.js', 'Express', 'MongoDB', 'Python', 'BigQuery'],
            challenges: [
                'Ensuring a seamless user experience while incorporating modern design principles.',
                'Maintaining high performance and scalability while integrating new features.',
                'Automating complex workflows and ensuring error-free execution.',
                'Handling large datasets and generating accurate, real-time insights.'
            ],
            impact: [
                'Increased user engagement and satisfaction, leading to a 30% rise in site traffic.',
                'Enhanced application performance and reliability, resulting in a 40% reduction in response times.',
                'Improved operational efficiency, reducing manual effort by 50% and minimizing errors.',
                'Provided actionable insights, leading to data-driven decisions and a 20% improvement in business processes.'
            ],
            links: [
                { name: 'Website', link: 'https://tweencraft.com' }
            ]
        },
    ];

    return (
        <div className="projects">
            <div className="projects-content">
                <h1>Projects</h1>
                <div className="project-list">
                    {companyProjects.map((Project, index) => (
                        <div key={index} className="project-item" style={{ "--background": `url(${Project.image})` }}>
                            <div className="project-content">
                                <div className="project-header">
                                    <h3>{Project.name}</h3>
                                </div>
                                <div className='project-details'>
                                    <p>{Project.description}</p>
                                    <p><strong>Role:</strong> {Project.role}</p>
                                    <p><strong>Technologies:</strong> {Project.technologies.join(', ')}</p>
                                    <p><strong>Challenges:</strong> {Array.isArray(Project.challenges) ? Project.challenges.map((challenge, i) => <span key={i}>{challenge}</span>) : Project.challenges}</p>
                                </div>
                                <div className='project-links'>
                                    {Project.links && Project.links.map((link, i) => (
                                        <a key={i} href={link.link} target="_blank" rel="noopener noreferrer">
                                            {link.name === "Website" ? (
                                                <i className="fa-brands fa-chrome"></i> 
                                            ) : link.name === "App Store" ? (
                                                <i className="fa-brands fa-app-store"></i>
                                            ) : link.name === "Play Store" ? (
                                                <i className="fa-brands fa-google-play"></i>
                                            ) : (
                                                <></>)}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Projects;