import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './PackagesList.css';
import { MarkdownConverter } from 'markdown-htmlify-react';




const PackageDetail = () => {
    const { packageName } = useParams();
    const [markdown, setMarkdown] = useState(`https://raw.githubusercontent.com/Amit2197/${packageName}/main/ReadMe.md`);


    return (
        <div className="section_package-detail">
            <div className='readme-container'>
            <MarkdownConverter url={markdown} />
            </div>
        </div>
        
    );
};

export default PackageDetail;