import React from 'react';
import { notebookData } from "../data/notebookData";

const Notebook = ({ selectedLanguage, selectedPage }) => {
    const pages = notebookData[selectedLanguage].pages;

    const renderContent = (content) => {
        return content.map((item, index) => {
            switch (item.type) {
                case "text":
                    return <p key={index} style={styles.p}>{item.value}</p>;
                case "code":
                    return (
                        <pre key={index} style={styles.pre}>
                            <code style={styles.code}>{item.value}</code>
                        </pre>
                    );
                case "image":
                    return <img key={index} src={item.value} alt="content" style={styles.image} />;
                default:
                    return null;
            }
        });
    };

    return (
        <div style={styles.container}>
            <div style={{textAlign: "right", padding: 12, textDecoration:"underline", color: "#f00"}}><a href='/playquize'>Play Quize</a></div>
            {selectedPage !== null && (
                <div style={styles.note}>
                    <h2 style={styles.h2}>{pages[selectedPage].description}</h2>
                    {renderContent(pages[selectedPage].content)}
                </div>
            )}
        </div>
    );
};

const styles = {
    container: {
        flex: 1,
        padding: "20px",
        overflowY: "auto",
        backgroundColor: "#121212",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        maxWidth: "800px",
        marginInline: "auto",
        color: "#fff",
    },
    note: {
        marginBottom: "20px",
    },
    pre: {
        backgroundColor: "#1e1e1e",
        padding: "10px",
        borderRadius: "5px",
        overflowX: "auto",
        marginBottom: "10px",
    },
    code: {
        fontFamily: "monospace",
        color: "#00e676", // Customize code color
    },
    image: {
        maxWidth: "100%",
        height: "auto",
        margin: "10px 0",
        borderRadius: "5px",
    },
    h2: {
        color: "#00e676",
        borderBottom: "2px solid #333",
        paddingBottom: "5px",
        marginBottom: "10px",
    },
    p: {
        lineHeight: 1.6,
        marginBottom: "10px",
    },
};

export default Notebook;