import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ProjectsPage from './pages/ProjectsPage';
import PackagesList from './pages/PackagesList';
import PackageDetail from './pages/PackageDetail';
import BlogPage from './pages/BlogPage';
import './App.css';
import NotFoundPage from './pages/NotFoundPage';
import Resume from './pages/Resume';
import NotebookPage from './pages/NotebookPage';
import PlayNotesQuize from './components/PlayNotesQuize';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/packages" element={<PackagesList />} />
          <Route path="/packages/:packageName" element={<PackageDetail />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/notebook/" element={<NotebookPage />} />
          <Route path="/playquize" element={<PlayNotesQuize />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;