import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import { ReactComponent as Logo } from '../assets/logo.svg';

const Navbar = () => {
    const { pathname } = useLocation();
    return (
        <nav className="navbar">
            <div className="logo">
                <Link to="/">
                    <Logo className="logo" fill={"#ffdd57"} />
                </Link>
            </div>
            <ul className="nav-links">
                <li><Link className={pathname === "/" ? "link active" : "link"} to="/">Home</Link></li>
                <li><Link className={pathname === "/about" ? "link active" : "link"} to="/about">About</Link></li>
                <li><Link className={pathname === "/projects" ? "link active" : "link"} to="/projects">Projects</Link></li>
                <li><Link className={pathname === "/blog" ? "link active" : "link"} to="/blog">Blog</Link></li>
            </ul>
        </nav>
    );
};

export default Navbar;