import React from 'react';
import './About.css';
import profilePhoto from '../assets/profile.jpg'; // Add your profile photo here
import { ReactComponent as GithubLogo } from '../assets/github.svg';
import { ReactComponent as LinkedInLogo } from '../assets/linkedin.svg';
import { ReactComponent as WhatsappLogo } from '../assets/whatsapp.svg';
import { ReactComponent as DownloadLogo } from '../assets/download.svg';
import { ReactComponent as GmailLogo } from '../assets/gmail.svg';
const resumeFile = "Amit_Kumar_Resume.pdf";

const About = () => {
    return (
        <div className="about">
            <div className="about-content">
                <div className="profile-container">
                    <img src={profilePhoto} alt="Amit Kumar" className="profile-photo" />
                </div>
                <h1>About Me</h1>
                <p>
                    I am <strong>Amit Kumar</strong>, an experienced Full Stack Developer proficient in <strong>React.js</strong> and <strong>Node.js</strong>, with foundational knowledge of <strong>React Native</strong>.
                    I specialize in creating scalable web and mobile applications with a focus on user experience and client satisfaction.
                </p>
                <p>
                    I have led successful cross-platform app launches, implementing performance optimizations that boosted engagement and efficiency.
                    My expertise includes designing and optimizing databases, developing APIs, integrating payment gateways, and implementing AI-driven features.
                </p>
                <div className="social-links">
                    <a href="https://www.linkedin.com/in/amit2197" target="_blank" rel="noopener noreferrer" className="social-icon" aria-label="LinkedIn Profile">
                        <LinkedInLogo width={32} height={32} />
                        <p>LinkedIn</p>
                    </a>
                    <a href="https://github.com/amit2197" target="_blank" rel="noopener noreferrer" className="social-icon" aria-label="GitHub Profile">
                        <GithubLogo width={32} height={32} color={"#fff"} />
                        <p>GitHub</p>
                    </a>
                    <a href="https://wa.me/+919852972838?text=Hi%20Amit%2C%0D%0A%0D%0AI%20would%20like%20to%20discuss%20collaboration%20opportunities%20with%20you.%20Please%20let%20me%20know%20your%20availability.%0D%0A%0D%0ABest%20regards%2C%0D%0A[Your%20Name]" target="_blank" rel="noopener noreferrer" className="social-icon" aria-label="WhatsApp">
                        <WhatsappLogo width={32} height={32} />
                        <p>WhatsApp</p>
                    </a>
                    <a href="mailto:amit115017@gmail.com?subject=Let's%20Collaborate&cc=hi@devamit.in&body=Hi%20Amit%2C%0D%0A%0D%0AI%20would%20like%20to%20discuss%20collaboration%20opportunities%20with%20you.%20Please%20let%20me%20know%20your%20availability.%0D%0A%0D%0ABest%20regards%2C%0D%0A[Your%20Name]" target="_blank" rel="noopener noreferrer" className="social-icon" aria-label="Email">
                        <GmailLogo width={32} height={32} />
                        <p>Email</p>
                    </a>
                    <a href={resumeFile} download="Amit_Kumar_Resume.pdf" type="application/octet-stream" className="social-icon" aria-label="Download Resume">
                        <DownloadLogo width={32} height={32} stroke={"#fff"} />
                        <p>Resume</p>
                    </a>
                </div>
                <section className="skills">
                    <h2>Skills</h2>
                    <div className="card">
                        <p><strong>Languages:</strong> Python3, JavaScript, HTML5, CSS3</p>
                    </div>
                    <div className="card">
                        <p><strong>Frameworks:</strong> NodeJs, ReactJs, ExpressJs, React Native, Flask, Socket.IO</p>
                    </div>
                    <div className="card">
                        <p><strong>Databases:</strong> MySQL, MongoDB, DynamoDB, PostgreSQL, BigQuery</p>
                    </div>
                    <div className="card">
                        <p><strong>Cloud & Tools:</strong> AWS, Kafka, GitHub, Visual Studio Code</p>
                    </div>
                </section>
                <section className="professional-summary">
                    <h2>Professional Summary</h2>
                    <div className="card">
                        <p>
                            I am a dedicated and detail-oriented software engineer with over 3.5 years of experience in full stack development.
                            My expertise lies in building robust and scalable web and mobile applications.
                            I have a proven track record of delivering high-quality software solutions that exceed client expectations.
                        </p>
                        <p>
                            I am proficient in modern development tools and methodologies, including DevOps practices.
                            My strong problem-solving skills and ability to work collaboratively in a team environment have contributed to the successful completion of numerous projects.
                        </p>
                    </div>
                </section>
                <section className="key-achievements">
                    <h2>Key Achievements</h2>
                    <ul>
                        <li>Led a team of developers to successfully launch a cross-platform mobile app, resulting in a 50% increase in user engagement.</li>
                        <li>Implemented performance optimizations for a web application, reducing loading times by 50% and enhancing user satisfaction.</li>
                        <li>Integrated AI-driven features into applications, improving user experience and functionality.</li>
                    </ul>
                </section>
                <section className="experience">
                    <h2>Experience</h2>
                    <div className="experience-item">
                        <h3>Senior Software Engineer @Phnk Networks India Pvt Ltd</h3>
                        <p>07/2022 - Present</p>
                        <ul>
                            <li>Proficient in MySQL database design, DynamoDB setup, AWS configuration, and Git version control.</li>
                            <li>Developed Node.js projects, including APIs with Kafka-based chat functionality.</li>
                            <li>Worked on integrating payment gateways like Razorpay and Google Play for in-app purchases. Additionally, I’ve implemented AI avatar generation and chat features.</li>
                            <li>Implemented real-time messaging systems for enhanced user engagement.</li>
                            <li>Oversee server-side, Android, and web development projects, handling everything from UI to hosting.</li>
                            <li>Played a significant role in website development, including design and architecture.</li>
                        </ul>
                    </div>
                    <div className="experience-item">
                        <h3>Software Engineer @Kalpkriti Pvt. Ltd</h3>
                        <p>09/2021 - 07/2022</p>
                        <ul>
                            <li>Redesigned the company website from scratch, ensuring a robust and efficient online presence.</li>
                            <li>Developed and maintained REST APIs using Node.js, Express.js, and MongoDB, addressing bugs and implementing new features.</li>
                            <li>Created automation scripts in Python, enhancing efficiency and resolving issues.</li>
                            <li>Utilizing BigQuery to design queries, schedule updates, and plot graphs based on the data.</li>
                        </ul>
                    </div>
                </section>
                <section className="problem-solving">
                    <h2>Problem Solving</h2>
                    <ul>
                        <li>Solved numerous problems on <a href="https://www.codewars.com/users/amit2197" target="_blank" rel="noopener noreferrer">Codewars</a>.</li>
                        <li>Active participant on <a href="https://www.hackerrank.com/amit2197" target="_blank" rel="noopener noreferrer">HackerRank</a>.</li>
                    </ul>
                </section>
            </div>
        </div>
    );
};

export default About; 