import React from "react";
import "./Home.css";
import profilePhoto from "../assets/profile.jpg";
import phnkgif from "../assets/phnkapp.gif";
import amritpexgif from "../assets/amritpex2023.gif";
import tweencraftgif from "../assets/tweencraft.gif";
import { ReactComponent as GithubLogo } from "../assets/github.svg";
import { ReactComponent as LinkedInLogo } from "../assets/linkedin.svg";
import { ReactComponent as WhatsappLogo } from "../assets/whatsapp.svg";
import { ReactComponent as DownloadLogo } from "../assets/download.svg";
import { ReactComponent as GmailLogo } from "../assets/gmail.svg";
import { ReactComponent as NpmLogo } from "../assets/npm.svg";
import { ReactComponent as CodeSandbox } from "../assets/codesandbox.svg";
import { ReactComponent as WebsiteLogo } from "../assets/website.svg";
const resumeFile = "Amit_Kumar_Resume.pdf";

const Home = () => {
  const [onHoverKeywords, setOnHoverKeywords] = React.useState(null);
  const OpenSourcePackages = [
    {
      name: "smart-env-switcher",
      description:
        "SmartEnvSwitcher is a lightweight Node.js library for managing environment variables across multiple environments, such as development, staging, and production. It simplifies the process of switching between environments and updating `.env` files programm",
      type: "npm",
      code: "npm i smart-env-switcher",
      logo: NpmLogo,
      keywords: ["nodejs", "environment", "switcher", "env", "typescript"],
      availableOn: [
        {
          name: "npm",
          link: "https://www.npmjs.com/package/smart-env-switcher",
          title: "View on npm",
        },
        {
          name: "github",
          link: "https://github.com/Amit2197/smart-env-switcher",
          title: "View source code",
        },
        {
          name: "codesandbox",
          link: "https://codesandbox.io/embed/smart-env-switcher-test-yx5lfp",
          title: "View demo",
        },
        {
          name: "devamit.in",
          link: "/packages/smart-env-switcher",
          title: "View documentation",
        },
      ],
    },
    {
      name: "markdown-htmlify",
      description:
        "A versatile Node.js package for converting Markdown to HTML. Fetch Markdown content from a URL, local file, or text string, and render it into HTML.",
      type: "npm",
      code: "npm i markdown-htmlify",
      logo: NpmLogo,
      keywords: [
        "markdown",
        "renderer",
        "html",
        "nodejs",
        "typescript",
        "html",
      ],
      availableOn: [
        {
          name: "npm",
          link: "https://www.npmjs.com/package/markdown-htmlify",
          title: "View on npm",
        },
        {
          name: "github",
          link: "https://github.com/Amit2197/markdown-htmlify",
          title: "View source code",
        },
        {
          name: "codesandbox",
          link: "https://codesandbox.io/embed/markdown-htmlify-test-yx5lfp",
          title: "View demo",
        },
        {
          name: "devamit.in",
          link: "/packages/markdown-htmlify",
          title: "View documentation",
        },
      ],
    },
    {
      name: "react-enhanced-text-editor",
      description:
        "A simple and customizable rich text editor built with React. This editor supports various text formatting options and media embedding, making it suitable for a wide range of applications.",
      type: "npm",
      code: "npm i react-enhanced-text-editor",
      logo: NpmLogo,
      keywords: [
        "react", "rich-text-editor", "editor", "text-format", "tingwysiwyg", "text-editor"
      ],
      availableOn: [
        {
          name: "npm",
          link: "https://www.npmjs.com/package/react-enhanced-text-editor",
          title: "View on npm",
        },
        {
          name: "github",
          link: "https://github.com/Amit2197/react-enhanced-text-editor",
          title: "View source code",
        },
        {
          name: "codesandbox",
          link: "https://codesandbox.io/embed/react-enhanced-text-editor-test-yx5lfp",
          title: "View demo",
        },
        {
          name: "devamit.in",
          link: "/packages/react-enhanced-text-editor",
          title: "View documentation",
        },
      ],
    },
  ];

  const companyProjects = [
    {
      name: "Phnk Networks Pvt Ltd",
      description: "Comprehensive App, Website, and Server Development",
      image: phnkgif,
      role: "Lead Developer",
      technologies: ["Node.js", "React", "AWS", "MySQL"],
      challenges: [
        "Ensuring low latency and high reliability in message delivery.",
        "Ensuring secure and smooth payment transactions.",
        "Implementing complex AI algorithms and ensuring real-time performance.",
        "Ensuring the platform can handle high traffic and scale efficiently.",
      ],
      impact: [
        "Improved user communication and engagement, leading to a 50% increase in active users.",
        "Increased in-app purchases by 25% and improved user satisfaction.",
        "Enhanced user experience and interaction, resulting in a 30% increase in user retention.",
        "Improved platform scalability and performance, supporting a growing user base.",
      ],
      links: [
        { name: "Website", link: "https://phnk.in" },
        {
          name: "App Store",
          link: "https://apps.apple.com/in/app/phnk/id6739332083",
        },
        {
          name: "Play Store",
          link: "https://play.google.com/store/apps/details?id=com.phnk",
        },
      ],
    },
    {
      name: "AMRITPEX 2023",
      description: "Comprehensive App, Website, and Server Development",
      image: amritpexgif,
      role: "Full Stack Developer",
      technologies: ["Node.js", "React", "MongoDB", "AWS"],
      challenges: [
        "Ensuring a seamless user experience across multiple features and maintaining app performance.",
        "Ensuring data security and handling concurrent user requests efficiently.",
        "Managing large volumes of data and ensuring quick data retrieval.",
      ],
      impact: [
        "Increased user engagement and app downloads by 35%.",
        "Improved data security and reduced server response times by 40%.",
        "Enhanced database performance and scalability, supporting a growing user base.",
      ],
    },
    {
      name: "Kalpkriti Pvt. Ltd",
      description: "Website Redesign and API Development",
      image: tweencraftgif,
      role: "Software Engineer",
      technologies: ["Node.js", "Express", "MongoDB", "Python", "BigQuery"],
      challenges: [
        "Ensuring a seamless user experience while incorporating modern design principles.",
        "Maintaining high performance and scalability while integrating new features.",
        "Automating complex workflows and ensuring error-free execution.",
        "Handling large datasets and generating accurate, real-time insights.",
      ],
      impact: [
        "Increased user engagement and satisfaction, leading to a 30% rise in site traffic.",
        "Enhanced application performance and reliability, resulting in a 40% reduction in response times.",
        "Improved operational efficiency, reducing manual effort by 50% and minimizing errors.",
        "Provided actionable insights, leading to data-driven decisions and a 20% improvement in business processes.",
      ],
      links: [{ name: "Website", link: "https://tweencraft.com" }],
    },
  ];

  const sellingProjects = [
    // { name: 'Chat System', price: '$100', description: 'Chat system based on Kafka with React.js and Node.js', image: projectImage, link: '#' },
    // Add more selling projects here
  ];

  return (
    <div className="home">
      <div className="home-content">
        <img src={profilePhoto} alt="Amit Kumar" className="profile-photo" />

        <h1>Amit Kumar</h1>
        <p>
          Experienced Full Stack Developer proficient in React.js and Node.js,
          with foundational knowledge of React Native.
        </p>
        <div className="social-links">
          {/* LinkedIn */}
          <a
            href="https://www.linkedin.com/in/amit2197"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
            aria-label="LinkedIn Profile"
          >
            <LinkedInLogo width={32} height={32} />
            <p>LinkedIn</p>
          </a>

          {/* GitHub */}
          <a
            href="https://github.com/amit2197"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
            aria-label="GitHub Profile"
          >
            <GithubLogo width={32} height={32} color={"#fff"} />
            <p>GitHub</p>
          </a>

          {/* WhatsApp */}
          <a
            href="https://wa.me/+919852972838?text=Hi%20Amit%2C%0D%0A%0D%0AI%20would%20like%20to%20discuss%20collaboration%20opportunities%20with%20you.%20Please%20let%20me%20know%20your%20availability.%0D%0A%0D%0ABest%20regards%2C%0D%0A[Your%20Name]"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
            aria-label="WhatsApp"
          >
            <WhatsappLogo width={32} height={32} />
            <p>WhatsApp</p>
          </a>

          {/* Gmail */}
          <a
            href="mailto:amit115017@gmail.com?subject=Let's%20Collaborate&cc=hi@devamit.in&body=Hi%20Amit%2C%0D%0A%0D%0AI%20would%20like%20to%20discuss%20collaboration%20opportunities%20with%20you.%20Please%20let%20me%20know%20your%20availability.%0D%0A%0D%0ABest%20regards%2C%0D%0A[Your%20Name]"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
            aria-label="Email"
          >
            <GmailLogo width={32} height={32} />
            <p>Email</p>
          </a>

          {/* Resume Download */}
          <a
            href={resumeFile}
            download="Amit_Kumar_Resume.pdf"
            type="application/octet-stream"
            className="social-icon"
            aria-label="Download Resume"
          >
            <DownloadLogo width={32} height={32} stroke={"#fff"} />
            <p>Resume</p>
          </a>
        </div>
      </div>
      <section className="overview">
        <h2>Overview</h2>
        <p>
          I am a software engineer with a strong background in web and mobile
          development. I have successfully uploaded applications to both the
          Play Store and App Store, and I have developed an open-source npm
          package.
        </p>
        <p>
          My expertise includes designing and optimizing databases, developing
          APIs, integrating payment gateways, and implementing AI-driven
          features. I am passionate about creating scalable applications with a
          focus on user experience.
        </p>
      </section>
      <section className="what-i-offer">
        <h2>What I Offer</h2>
        <div className="offer-list">
          <div className="offer-item">
            <h3>01</h3>
            <h4>Creative Design</h4>
            <p>
              Redefining what it means to build a minimum viable product with
              enriched creativity.
            </p>
          </div>
          <div className="offer-item">
            <h3>02</h3>
            <h4>Technical Timeline</h4>
            <p>
              Building a digital product or service but it's taking forever? I
              put the right tech at work to make results show quick.
            </p>
          </div>
          <div className="offer-item">
            <h3>03</h3>
            <h4>Client Engagement</h4>
            <p>
              Anticipating customers’ needs and keeping up with them to foster
              lasting loyalty, and hence, business growth.
            </p>
          </div>
          <div className="offer-item">
            <h3>04</h3>
            <h4>Analytics</h4>
            <p>
              There's anything digital analytics can do for us whether it's
              about making business stand apart or top the searches.
            </p>
          </div>
          <div className="offer-item">
            <h3>05</h3>
            <h4>Development</h4>
            <p>
              Native apps, web apps, custom designed pages, CMS, chatbots,
              plugins, or entire ventures.. No digital hurdle is too big to
              tackle.
            </p>
          </div>
          <div className="offer-item">
            <h3>06</h3>
            <h4>SEO Optimized</h4>
            <p>
              Determining the relative competition of a keyword can be a fairly
              complex task. I code SEO optimized and accelerated.
            </p>
          </div>
        </div>
      </section>
      <section className="npm-packages">
        <h2>Open Source Packages</h2>
        <div className="package-list">
          {OpenSourcePackages.map((Pkg, index) => (
            <div key={index} className="package-item">
              <div className="package-logo">
                        <Pkg.logo className='logo'/>
                        </div>
                        <div style={{flex: 1}}>
                <a href={Pkg.link} target="_blank" rel="noopener noreferrer">
                  <h3>{Pkg.name}</h3>
                  <p>{Pkg.description}</p>
                </a>
                <pre>
                  <code>{Pkg.code}</code>
                </pre>
                <div className="keywords">
                  {Pkg.keywords.map((keyword, key_index) => (
                    <span key={key_index}>{keyword}</span>
                  ))}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  gap: "1rem",
                  justifyContent: "center",
                }}
              >
                {Pkg.availableOn.map((Available, av_index) => (
                  <div
                    key={av_index}
                    style={{ position: "relative" }}
                    onMouseOver={() => setOnHoverKeywords([index, av_index])}
                    onMouseOut={() => setOnHoverKeywords(null)}
                  >
                    {Available.name === "npm" ? (
                      <a
                        href={Available.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecorationColor: "#ffdd57",
                          color: "#ffdd57",
                          borderRadius: 20,
                          width: 32,
                          height: 32,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#444",
                          boxShadow: "rgba(21, 21, 21, 0.7) 3px 2px 2px 1px",
                          overflow: "hidden",
                        }}
                      >
                        <NpmLogo className="logo" width={32} height={32} />
                      </a>
                    ) : Available.name === "github" ? (
                      <a
                        href={Available.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecorationColor: "#ffdd57",
                          color: "#ffdd57",
                          borderRadius: 20,
                          width: 32,
                          height: 32,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#444",
                          boxShadow: "rgba(21, 21, 21, 0.7) 3px 2px 2px 1px",
                          overflow: "hidden",
                        }}
                      >
                        <GithubLogo className="logo" width={32} height={32} />
                      </a>
                    ) : Available.name === "devamit.in" ? (
                      <a
                        href={Available.link}
                        rel="noopener noreferrer"
                        style={{
                          textDecorationColor: "#ffdd57",
                          color: "#ffdd57",
                          borderRadius: 20,
                          width: 32,
                          height: 32,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#444",
                          boxShadow: "rgba(21, 21, 21, 0.7) 3px 2px 2px 1px",
                          overflow: "hidden",
                        }}
                      >
                        <WebsiteLogo
                          className="logo"
                          width={32}
                          height={32}
                          stroke="#fff"
                        />
                      </a>
                    ) : (
                      <a
                        href={Available.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecorationColor: "#ffdd57",
                          color: "#ffdd57",
                          borderRadius: 20,
                          width: 32,
                          height: 32,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#444",
                          boxShadow: "rgba(21, 21, 21, 0.7) 3px 2px 2px 1px",
                          overflow: "hidden",
                        }}
                      >
                        <CodeSandbox className="logo" width={32} height={32} />
                      </a>
                    )}

                    {onHoverKeywords &&
                      onHoverKeywords?.[0] === index &&
                      onHoverKeywords?.[1] === av_index && (
                        <div
                          style={{
                            position: "absolute",
                            left: 42,
                            top: 3,
                            display: "inline-block",
                            backgroundColor: "rgba(0, 0, 0, 0.64)",
                            textWrap: "nowrap",
                            padding: "2px 12px",
                            borderRadius: 12,
                          }}
                        >
                          {Available.title}
                        </div>
                      )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        {OpenSourcePackages.length >= 2 && (
          <div style={{ marginBlock: 20 }}>
            <a
              href="/packages"
              style={{ color: "#ffdd57", textDecoration: "underline" }}
            >
              Explore more
            </a>
          </div>
        )}
      </section>

      <section className="company-projects">
        <h2>Professional Projects</h2>
        <div className="project-list">
          {companyProjects.map((Project, index) => (
            <div
              key={index}
              className="project-item"
              style={{ "--background": `url(${Project.image})` }}
            >
              <div className="project-content">
                <div className="project-header">
                  <h3>{Project.name}</h3>
                </div>
                <div className="project-details">
                  <p>{Project.description}</p>
                  <p>
                    <strong>Role:</strong> {Project.role}
                  </p>
                  <p>
                    <strong>Technologies:</strong>{" "}
                    {Project.technologies.join(", ")}
                  </p>
                  <p>
                    <strong>Challenges:</strong>{" "}
                    {Array.isArray(Project.challenges)
                      ? Project.challenges.map((challenge, i) => (
                          <span key={i}>{challenge}</span>
                        ))
                      : Project.challenges}
                  </p>
                </div>
                <div className="project-links">
                  {Project.links &&
                    Project.links.map((link, i) => (
                      <a
                        key={i}
                        href={link.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link.name === "Website" ? (
                          <i className="fa-brands fa-chrome"></i>
                        ) : link.name === "App Store" ? (
                          <i className="fa-brands fa-app-store"></i>
                        ) : link.name === "Play Store" ? (
                          <i className="fa-brands fa-google-play"></i>
                        ) : (
                          <></>
                        )}
                      </a>
                    ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      {sellingProjects && sellingProjects.length > 0 && (
        <section className="selling-projects">
          <h2>Projects for Sale</h2>
          <div className="project-list">
            {sellingProjects.map((project, index) => (
              <div key={index} className="project-item">
                <img src={project.image} alt={project.name} />
                <h3>{project.name}</h3>
                <p>{project.price}</p>
                <p>{project.description}</p>
                <a
                  href={project.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Project
                </a>
              </div>
            ))}
          </div>
        </section>
      )}
    </div>
  );
};

export default Home;
